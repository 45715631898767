import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link} from "react-router-dom";
import {Container, Row, Col, Badge} from "react-bootstrap";
import {BsGear, BsPeople, BsSearch, BsBoxArrowInUpLeft, BsCardList, BsFile} from 'react-icons/bs';
import './dashboard.scss'
import {getUserRecords, getSelectedRecord, updateSearchField, logoutUser} from  '../../redux/actions'
import logo from '../../assets/logo.png';
import { UserProfile, handleSessionControlLogout } from '../../config';
import { AuthenticatedSessionControl } from  'react-session-control';
import RequestAccessList from "../../components/request-access-list";


class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      showModal: false,
      selected: {}
    };
  }

  handleShow (){
    this.setState({
      showModal: true
    })
  }

  handleClose = () => this.setState({showModal: false})

  componentWillMount =()=>{
    if(sessionStorage.getItem('accessToken') === undefined ){

      this.props.history.push('/')
    }

    // this.props.getUserRecords({});
  }

  navigate(to){
    this.props.history.push(to)
  }

  render() {

    const {
      props:{
        logoutUser
      },
      state:{
        showModal
      }
    } = this

    const isLoggedIn = UserProfile.getToken()
    const accessType = UserProfile.getAccessType()
    
    return (
      <div>
      <Container className="dashboardPage" fluid>
      { UserProfile.getToken()?<AuthenticatedSessionControl
            inactivityTimeout={90}
            modalInactivityTimeout={30}
            storageTokenKey="accessToken"
            onLogout={handleSessionControlLogout}
            title={'Inactivity Alert'}
        />:null }
          { isLoggedIn?
              <Row className="dashboard">
                    <Col xs={12} md={12} className="mainSection">
                      <main>
                        <div className='menuBox'>
                          <img src={logo} alt='logo' width='100'/>
                          <br /><br />
                          <p>
                            <strong styles={{color:'#666'}}>DCIC E-ARCHIVES DASHBOARD</strong>
                            {accessType&&<div><Badge variant="success">{accessType}</Badge></div>}
                          </p>

                          <div className='menuBoxRow'>
                            <div onClick={()=>this.navigate(`${accessType==="legal"?'/legal/search':'/search'}`)}><BsSearch /> <Link to={`${accessType==="legal"?'/legal/search':'/search'}`}> FIND A RECORD</Link></div>
                            {UserProfile.getRole() < 3?<div onClick={()=>this.navigate(`/users`)}><BsPeople /> <Link to={`/users`}> USER MANAGEMENT</Link></div>:null}
                            <div onClick={()=>this.navigate(`/settings`)}><BsGear /> <Link to={`/settings`}> SETTINGS</Link></div>
                            {UserProfile.getRole() !==0?<div onClick={()=>this.navigate(`/records`)}><BsFile /> <Link to={`/records`}> FILE MOVEMENT</Link></div>:null}
                            {accessType?<div onClick={()=>{this.handleShow()}}><BsCardList /> <Link to={`/dashboard`}> DOCUMENT REQUESTS</Link></div>:null}

                            <div onClick={()=>logoutUser()}><BsBoxArrowInUpLeft />  <Link to={``}> LOGOUT</Link></div>
                          </div>
                        </div>
                      </main>
                    </Col>
                </Row>:
              <div></div>
          }
      </Container>
      <RequestAccessList show={showModal} handleClose={this.handleClose}/>
      </div>
    )
  }
}


const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ getUserRecords, getSelectedRecord, updateSearchField, logoutUser }
)(DashboardPage);