import { legal } from '../../constants/types'

const INITIAL_STATE = {
	data: [],
	isGettingDocuments: false,
	isFetchingAttachment: false,
	isUpdatingField: false,
	isRequestingApproval: false,
	isRequestingFile: false,
	isDenyingRequest: false,
	isAssigningAccess: false,
	requestedDocuments: [],
	attachedDocuments: [],
	approveResponse: {},
	requestFileResponse: {},
	verificationResponse:{},
	denyResponse: {},
	assignAccessResponse: {},
	error: '',
	success: '',
};

export const legalReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {

		case legal.GET_REQUESTED_FILES_STARTED:
			return { ...state, isGettingDocuments:true };

		case legal.GET_REQUESTED_FILES_SUCCESSFUL:
			return { ...state, requestedDocuments: action.payload , isGettingDocuments:false};

		case legal.GET_REQUESTED_FILES_FAILED:
			return { ...state, error: action.payload, isGettingDocuments:false};


		case legal.REQUEST_FILE_STARTED:
			return { ...state, isRequestingFile:true };

		case legal.REQUEST_FILE_SUCCESSFUL:
			return { ...state, error:'', requestFileResponse: action.payload ,isRequestingFile:false};

		case legal.REQUEST_FILE_FAILED:
			return { ...state, error: action.payload, requestFileResponse:'', isRequestingFile:false};


		case legal.VERIFY_REQUESTED_FILES_STARTED:
			return { ...state, isVerifyingRequest:true };

		case legal.VERIFY_REQUESTED_FILES_SUCCESSFUL:
			return { ...state, error:'', verificationResponse: action.payload ,isVerifyingRequest:false};

		case legal.VERIFY_REQUESTED_FILES_FAILED:
			return { ...state, verificationResponse: action.payload, isVerifyingRequest:false};

				
		case legal.APPROVE_REQUESTED_FILES_STARTED:
			return { ...state, isRequestingApproval:true };

		case legal.APPROVE_REQUESTED_FILES_SUCCESSFUL:
			return { ...state, error:'', approveResponse: action.payload ,isRequestingApproval:false};

		case legal.APPROVE_REQUESTED_FILES_FAILED:
			return { ...state, approveResponse: action.payload, isRequestingApproval:false};


		case legal.DENY_REQUESTED_FILES_STARTED:
			return { ...state, isDenyingRequest:true };

		case legal.DENY_REQUESTED_FILES_SUCCESSFUL:
			return { ...state, denyResponse: action.payload ,isDenyingRequest:false};

		case legal.DENY_REQUESTED_FILES_FAILED:
			return { ...state, denyResponse: action.payload, isDenyingRequest:false};
	

		case legal.ASSIGN_FILE_ACCESS_STARTED:
			return { ...state, isAssigningAccess:true };

		case legal.ASSIGN_FILE_ACCESS_SUCCESSFUL:
			return { ...state, assignAccessResponse: action.payload ,isAssigningAccess:false};

		case legal.ASSIGN_FILE_ACCESS_FAILED:
			return { ...state, assignAccessResponse: action.payload, isAssigningAccess:false};
			

		case legal.GET_ATTACHED_FILE_STARTED:
			return { ...state, isDenyingRequest:true };

		case legal.GET_ATTACHED_FILE_SUCCESSFUL:
			return { ...state, error:'', attachedDocuments: action.payload ,isDenyingRequest:false};

		case legal.GET_ATTACHED_FILE_FAILED:
			return { ...state, error: action.payload, success:'', isDenyingRequest:false};
	
				
		default:
			return state;
	}
};
