import axios from 'axios';
import { legal } from '../../constants/types'
import { API, UserProfile } from '../../config'

export const getRequestedDocuments = (department='', userId='', userRole=0) => {

  return dispatch => {

    dispatch({ type: legal.GET_REQUESTED_FILES_STARTED });

    return axios
      .post(`${API.baseURL}/legal/request-list`,
        {
          department,
          userId,
          userRole
        },
        {
          headers: { token: UserProfile.getToken(), user: UserProfile.getUsername() }
        })
      .then(response => {
        let data = response.data.data
        return dispatch({ type: legal.GET_REQUESTED_FILES_SUCCESSFUL, payload: data });

      })
      .catch(error => {

        return dispatch({ type: legal.GET_REQUESTED_FILES_FAILED, payload: error.response });

      });
  };

}

export const requestDocumentAccess = (data={document:'', reason:'', user_dept:0}) => {
  return dispatch => {

    dispatch({ type: legal.REQUEST_FILE_STARTED });
    return axios
      .post(`${API.baseURL}/legal/file/request-access`, { ...data }, { headers: { token: UserProfile.getToken(), user: UserProfile.getUsername() } })
      .then(response => {
        dispatch({ type: legal.REQUEST_FILE_SUCCESSFUL, payload: {success: true}});
      })
      .catch(error => {
        dispatch({ type: legal.REQUEST_FILE_FAILED, payload: {...error.response, error: true} });
      });
  };
}

export const approveRequestedDocument = (requests=[], reason) => {
  return dispatch => {

    dispatch({ type: legal.APPROVE_REQUESTED_FILES_STARTED });

    return axios
      .post(`${API.baseURL}/legal/file/request-approve`, { requests, reason }, { headers: { token: UserProfile.getToken(), user: UserProfile.getUsername() } })
      .then(response => {
        dispatch({ type: legal.APPROVE_REQUESTED_FILES_SUCCESSFUL, payload: response.data });
      })
      .catch(error => {
        const errorResponse = {
          message: "Something wrong happened",
          status: 400
        }
        dispatch({ type: legal.APPROVE_REQUESTED_FILES_FAILED, payload: errorResponse });
      });
  };
}

export const verifyRequestedDocument = (requests=[]) => {
  return dispatch => {

    dispatch({ type: legal.VERIFY_REQUESTED_FILES_STARTED });

    return axios
      .post(`${API.baseURL}/legal/file/request-verify`, { requests, user_dept: UserProfile.getDepartment()}, { headers: { token: UserProfile.getToken(), user: UserProfile.getUsername() } })
      .then(response => {
        dispatch({ type: legal.VERIFY_REQUESTED_FILES_SUCCESSFUL, payload: {
            status: 200,
            message: "Request has been verified successfully & forwarded for approval"
          }});
      })
      .catch(error => {
        const errorResponse = {
          message: "Something wrong happened",
          status: 400
        }
        dispatch({ type: legal.VERIFY_REQUESTED_FILES_FAILED, payload: errorResponse });
      });
  };
}

export const denyRequestedDocument = (data={requests:[], reason:""}) => {
  return dispatch => {

    dispatch({ type: legal.DENY_REQUESTED_FILES_STARTED });

    return axios
      .post(`${API.baseURL}/legal/file/request-deny`, {...data }, { headers: { token: UserProfile.getToken(), user: UserProfile.getUsername() } })
      .then(response => {
        dispatch({ type: legal.DENY_REQUESTED_FILES_SUCCESSFUL, payload: response.data.data });
      })
      .catch(error => {
        dispatch({ type: legal.DENY_REQUESTED_FILES_FAILED, payload: error.response });
      });
  };
}

export const assignDocumentToRequested = (request, attachment) => {
  return dispatch => {

    dispatch({ type: legal.ASSIGN_FILE_ACCESS_STARTED });

    console.log(request, attachment)
    const attachedFileNo = attachment['File_No']
    const requestId = request['Id']

    return axios
      .post(`${API.baseURL}/legal/file/assign-access`, {attachedFileNo, requestId}, { headers: { token: UserProfile.getToken(), user: UserProfile.getUsername() } })
      .then(response => {
        dispatch({ type: legal.ASSIGN_FILE_ACCESS_SUCCESSFUL, payload: {
          status: 200,
          message: "Document has been assigned to user successfully"
        }});
      })
      .catch(error => {
        dispatch({ type: legal.ASSIGN_FILE_ACCESS_FAILED, payload: error.response });
      });
  };
}
export const getAttachedDocuments = (recordId) => {
  return dispatch => {

    dispatch({ type: legal.GET_ATTACHED_FILE_STARTED });

    return axios
      .post(`${API.baseURL}/legal/file/attachments`, { recordId }, { headers: { token: UserProfile.getToken(), user: UserProfile.getUsername() } })
      .then(response => {

        let data = response.data.data || []
        dispatch({ type: legal.GET_ATTACHED_FILE_SUCCESSFUL, payload: data });
      })
      .catch(error => {
        dispatch({ type: legal.GET_ATTACHED_FILE_FAILED, payload: error.response });
      });
  };
}