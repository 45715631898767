import React, { Component } from "react";
import { connect } from 'react-redux';
import { Col, Form, Button, Spinner} from "react-bootstrap";
import Moment from 'moment';

import './change-password.scss'
import { resetUserPassword} from '../../../../redux/actions'
import { UserProfile } from '../../../../config';

class ChangePasswordSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      showModal: false,
      selected: {},
      activeList: "report",
      password0: "",
      password1: "",
      password2: "",
      validated: false
    };
  }

  componentWillMount =()=>{

    if(!UserProfile.getToken() ){
      window.location = '/'
    }

    // this.props.getUserRecords({});
  }

  formatDate = (date) => {
    Moment.locale('en');
    return Moment(date).format('d-MMM-YYYY');
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;

    const { password0, password1, password2} = this.state
  
    if(password1 === password2) {
      this.setState({validated: true})
      event.preventDefault();
      event.stopPropagation();
    }

    if (form.checkValidity() === false) {
      this.setState({validated: true})
      event.preventDefault();
      event.stopPropagation();
    }

    if (form.checkValidity()) {

      event.preventDefault();
      event.stopPropagation();

      const reqData = {
        currentPassword: password0,
        newPassword: password1,
        confirmPassword: password2
      }
      this.props.resetUserPassword(reqData).then(res => {

        if(res){
          alert("Your password has been successfully update")
        }else{
          alert("Password reset has failed")
        }

      })

    }
    
  };
  
  handleChange = (event) => {

    if(event.target.name !== undefined ){
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  render() {
    const {
      user: {
        isResettingPassword,
      }
    } = this.props
    
    return (
        <div className="mainSettings">
          <div className="dashTitle">Update your login credentials</div>
          <main>
            {/* form here */}

            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit} style={{width:"80%",textAlign:'left'}}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridFName">
                      <Form.Label>Current password <small>(LEAVE BLANK TO LEAVE UNCHANGED)</small></Form.Label>
                      <Form.Control type="text" name='password0' placeholder="" 
                        required onChange={this.handleChange}/>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridLName">
                      <Form.Label>New password <small>(LEAVE BLANK TO LEAVE UNCHANGED)</small></Form.Label>
                      <Form.Control type="password" name='password1'
                        placeholder="" required onChange={this.handleChange} />
                    </Form.Group>
                  </Form.Row>

                  <Form.Group controlId="formGridAddress">
                    <Form.Label>Confirm new password</Form.Label>
                    <Form.Control type="password" name='password2'
                        placeholder="" required onChange={this.handleChange} isInvalid={this.state.password1 !== this.state.password2} />
                    </Form.Group>

                  <Button type="submit" variant="primary" >
                    {
                      isResettingPassword?<Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />:<></>
                    }
                    {isResettingPassword?'Please wait..':'Update'}
                    
                  </Button>

              </Form>
          </main>
        </div>
    )
  }
}


const mapStateToProps = ({ user }) => {
	return {
    user,
	};
};

export default connect(
	mapStateToProps,
	{ resetUserPassword}
)(ChangePasswordSection);