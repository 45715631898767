import { user } from '../../constants/types'

const INITIAL_STATE = {
	data: {	},
    isLoggingin: false,
	loggedIn: false,
	error: '',
	users: [],
	isGettingUser: false,
	isCreatingUser: false,
	createdUser: {},
	accessToken: ''

};

export const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case user.LOGOUT:

			return { ...INITIAL_STATE };

		case user.LOGIN_STARTED:
			return { ...state, isLoggingin:true };

		case user.LOGIN_SUCCESSFUL:
			return { ...state, data: action.payload , isLoggingin:false, loggedIn:true };

		case user.LOGIN_FAILED:
			return { ...state, error: action.payload, isLoggingin:false, loggedIn: false  };

		case user.GET_USERS_STARTED:
			return { ...state, isGettingUser:true };

		case user.GET_USERS_SUCCESSFUL:
			return { ...state, users: action.payload , isGettingUser:false };

		case user.GET_USERS_FAILED:
			return { ...state, error: action.payload, isGettingUser:false};

		case user.CREATE_USER_STARTED:
			return { ...state, isCreatingUser:true };

		case user.CREATE_USER_SUCCESSFUL:
			return { ...state, createdUser: action.payload , isCreatingUser:false, accessToken: action.payload.accessToken};

		case user.CREATE_USER_FAILED:
			return { ...state, error: action.payload, isCreatingUser:false};

		default:
			return state;
	}
};
