import React, { Component } from "react";
import { Form, Button, Modal, Col, Alert, Jumbotron, Container } from "react-bootstrap";
import { connect } from 'react-redux';
import './requestAccess.scss'
import { getUserRecords, createUser, logoutUser, requestDocumentAccess } from '../../redux/actions'
import { UserProfile } from "../../config";

class RequestAccessModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      showModal: false,
      selected: {},
      validated: false,
      requestReason: "",
      documentType: ""
    };
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    const {
      legal: {
        success
      }
    } = this.props

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    event.stopPropagation();

    this.setState({ validated: true });
    if (form.checkValidity()) {

      setTimeout(async () => {

        await this.props.requestDocumentAccess({
          record_id: this.props['recordId'],
          document_type: this.state.documentType,
          reason: this.state.requestReason,
          user_dept: UserProfile.getDepartment()
        })

      }, 200)

      if (success !== '') {
        form.reset()
        // this.props.handleClose()
      }
    }


  };


  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    // const { target } = event;
    // const { name, value } = target;

  }

  render() {

    const {
      legal: {
        isRequestingFile,
        success,
        requestFileResponse,
        error
      }
    } = this.props

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        backdrop="static"
        size={'md'}
        keyboard={false}
        scrollable={true}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <small>Request Document Access</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            requestFileResponse.success ?
              <Jumbotron fluid>
                <Container>
                  <h1>Successful</h1>
                  <p>
                    Your request has been successfully submitted for verification by your Supervisor
                  </p>
                </Container>
              </Jumbotron>
              :

              <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} controlId="requestForm.documentType">
                    <Form.Label>Select Document Type</Form.Label>
                    <Form.Control as="select" name='documentType' onChange={this.handleChange}>
                      <option value={"..."}>...</option>
                      <option value={"passport"}>Passport File</option>
                      <option value={"immigration"}>Immigration File</option>
                    </Form.Control>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="requestForm.requestReason">
                    <Form.Label>Reason for the request :</Form.Label>
                    <Form.Control as="textarea" rows={3} name='requestReason' onChange={this.handleChange} />
                  </Form.Group>
                </Form.Row>
                <Button type="submit" variant="primary" disabled={isRequestingFile}>{isRequestingFile ? 'Please wait..' : 'Request Access'}{isRequestingFile}</Button>
              </Form>

          }
        </Modal.Body>
        <Modal.Footer>
          {/* {
            requestFileResponse.success ? <Alert variant={'success'}>
              Your document request has been submitted successfully
            </Alert> : null
          } */}
          {
            requestFileResponse['error'] ? <Alert variant={'warning'}>
              Something wrong happened, try again later
            </Alert> : null
          }
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = ({ user, records, legal }) => {
  return {
    user,
    records,
    legal
  };
};

export default connect(
  mapStateToProps,
  { getUserRecords, createUser, logoutUser, requestDocumentAccess }
)(RequestAccessModal);