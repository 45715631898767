import React, { Component } from "react";
import { connect } from 'react-redux';
import {Container, Row, Col, Button,ListGroup} from "react-bootstrap";
import Moment from 'moment';

import './settings.scss'
import {getUserRecords, getSelectedRecord, updateSearchField, logoutUser, getSystemOptions} from '../../redux/actions'
import { AuthenticatedSessionControl } from  'react-session-control';
import { UserProfile, handleSessionControlLogout } from '../../config';
import GeneralSettings from "./components/general-settings"
import ChangePassword from "./components/change-password"
import NotificationSettings from "./components/notifications"
import CheckoutRecordModal from '../../components/checkout-record'
import CheckinRecordModal from '../../components/checkin-record'
import UserBar from '../../components/user-bar'

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      showModal: false,
      showCheckoutRecordModal: false,
      showCheckinRecordModal: false,
      selected: {},
      activeList: "report",
      selectedMenuItem: 0
    };
  }

  componentWillMount =()=>{

    if(!UserProfile.getToken() ){
      window.location = '/'
    }

  }

  formatDate = (date) => {
    Moment.locale('en');
    return Moment(date).format('d-MMM-YYYY');
  }

  handleClose = () => this.setState({showModal: false, showCheckoutRecordModal:false, showCheckinRecordModal:false});


  handleDisplayShow = () => {
    this.setState({showCheckoutRecordModal: true})
  }

  handleCheckinDisplay = () => {
    this.setState({showCheckinRecordModal: true})
  }

  setSelectedOption = (key) => this.setState({selectedMenuItem: key})

  componentDidMount(){
    this.props.getSystemOptions()
  }
  render() {
    const {
      records: { systemOptions },
    } = this.props

    const  {
      selectedMenuItem
    } = this.state

    const inactivityTimeout = Array.isArray(systemOptions) ? 
        systemOptions.filter(item => item.option_name ==='inactivityTimeout')[0]: undefined
    const modalInactivityTimeout = Array.isArray(systemOptions) ?
        systemOptions.filter(item => item.option_name ==='modalInactivityTimeout')[0]:undefined

    const role = UserProfile.getRole()?Number(UserProfile.getRole()):0
    return (
      <div>
      <Container className="settingsPage" fluid>
      { UserProfile.getToken()?<AuthenticatedSessionControl
                inactivityTimeout={inactivityTimeout?Number(inactivityTimeout.option_values):360}
                modalInactivityTimeout={modalInactivityTimeout?Number(modalInactivityTimeout.option_values):60}
                storageTokenKey="accessToken"
                onLogout={handleSessionControlLogout}
                title={'Inactivity Alert'}
            />:null }
          { UserProfile.getToken()?
              <Row className="search">
                    <Col xs={12} md={3} className="sideBar">
                      <UserBar title={"MENU"} />
                        <ListGroup defaultActiveKey={0} onSelect={(key)=>this.setSelectedOption(key)}>
                          <ListGroup.Item action variant="secondary" eventKey={0}>
                            Change Password
                          </ListGroup.Item>
                          {role === 3? <ListGroup.Item action variant="secondary" eventKey={1}>
                            Notification settings
                          </ListGroup.Item>: null}
                          { role=== 3?<ListGroup.Item action variant="secondary" eventKey={2}>
                            System options
                          </ListGroup.Item>: null}
                        </ListGroup>
                      <br />
                      <div className='actionBtn'>
                        {/* <Button variant="secondary" size="sm" onClick={logoutUser}>
                            close
                        </Button>{' '} */}
                        <Button variant="secondary" size="sm" href={'/dashboard'}>
                            Back
                        </Button>{' '}    
                        <Button variant="warning" size="sm">
                          Logout
                        </Button> {' '}    
                    </div>
                    </Col>
                    <Col xs={12} md={9} className="mainSection">
                        {
                          // <RecordTrackerReport />
                        }
                        <header>
                          <h4 >Settings Page</h4>
                        </header>

                        <main>
                          {
                            Number(selectedMenuItem) === 0 ? <ChangePassword />:null
                          }

                          {
                            Number(selectedMenuItem) === 1 ? <NotificationSettings />:null
                          }

                          {
                            Number(selectedMenuItem) === 2 ? <GeneralSettings />:null
                          }
                        </main>
                    </Col>
                </Row>:
              <div></div>
          }
      </Container>
      <CheckoutRecordModal show={this.state.showCheckoutRecordModal} handleClose={this.handleClose}/>
      <CheckinRecordModal show={this.state.showCheckinRecordModal} handleClose={this.handleClose}/>
      </div>
    )
  }
}


const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ getUserRecords, getSelectedRecord, updateSearchField, logoutUser, getSystemOptions }
)(SettingsPage);