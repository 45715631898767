import { records } from '../../constants/types'
import {seachFieldSchema} from '../../config';

const INITIAL_STATE = {
	data: [],
    isFetchingRecords: false,
    isFetchingAttachment: false,
    isUpdatingField: false,
	error: '',
	selectedRecord: {},
	selectedRecordSet: false,
	attachmentFetched: false,
	fieldUpdated: false,
	searchFields: seachFieldSchema,
	attachment: null,
	isFetchingLogs: false,
	queryLogs: [],
	checkinResponse:{status:"",},
	checkXResponse:{status:"", data:{}},
	checkoutResponse: {status:"",},
	isCheckingRecord: false,
	isCheckingoutRecord: false,
	isGettingExistingRecord: false,
	isGettingSystemOptions: false,
	systemOptions: [],
	fileMovtStats: []
};

export const recordsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {

		case records.FETCH_RECORDS_STARTED:
			return { ...state, isFetchingRecords:true };

		case records.FETCH_RECORDS_SUCCESSFUL:
			return { ...state, data: action.payload , isFetchingRecords:false};

		case records.FETCH_RECORDS_FAILED:
			return { ...state, error: action.payload, isFetchingRecords:false};

		case records.SET_SELECTED_RECORD_STARTED:
			return { ...state,};

		case records.SET_SELECTED_RECORD_SUCCESSFUL:
			return { ...state, selectedRecord: action.payload, selectedRecordSet: true};

		case records.SET_SELECTED_RECORD_FAILED:
			return { ...state, error: action.payload, selectedRecordSet: false};

		case records.FETCH_ATTACHMENT_STARTED:
			return { ...state,isFetchingAttachment: true, attachment: null};

		case records.FETCH_ATTACHMENT_SUCCESSFUL:
			return { ...state, attachment: action.payload, attachmentFetched: true, isFetchingAttachment: false};

		case records.FETCH_ATTACHMENT_FAILED:
			return { ...state, error: action.payload, attachmentFetched: false, isFetchingAttachment: false};

		case records.UPDATE_SEACH_FIELDS_STARTED:
			return { ...state,isUpdatingField: true};

		case records.UPDATE_SEACH_FIELDS_SUCCESSFUL:
			return { ...state, searchFields:action.payload, fieldUpdated: true, isUpdatingField: false};

		case records.UPDATE_SEACH_FIELDS_FAILED:
			return { ...state, error: action.payload, fieldUpdated: false, isUpdatingField: false};
		
		case records.FETCH_LOGS_STARTED:
			return { ...state, isFetchingLogs: true, attachment: null};

		case records.FETCH_LOGS_SUCCESSFUL:
			return { ...state, queryLogs: action.payload, isFetchingLogs: false};

		case records.FETCH_LOGS_FAILED:
			return { ...state, error: action.payload, isFetchingLogs: false};

		case records.CHECKIN_RECORD_STARTED:
			return { ...state, isCheckingRecord: true,};

		case records.CHECKIN_RECORD_SUCCESSFUL:
			return { ...state, checkinResponse: action.payload, isCheckingRecord: false};

		case records.CHECKIN_RECORD_FAILED:
			return { ...state, error: action.payload, isCheckingRecord: false};

		case records.CHECKOUT_RECORD_STARTED:
			return { ...state, isCheckingoutRecord: true,};

		case records.CHECKOUT_RECORD_SUCCESSFUL:
			return { ...state, checkoutResponse: action.payload, isCheckingoutRecord: false};

		case records.CHECKOUT_RECORD_FAILED:
			return { ...state, error: action.payload, isCheckingoutRecord: false};


		case records.CHECK_EXISTING_RECORD_STARTED:
			return { ...state, isGettingExistingRecord: true,};

		case records.CHECK_EXISTING_RECORD_SUCCESSFUL:
			return { ...state, checkXResponse: action.payload, isGettingExistingRecord: false};

		case records.CHECK_EXISTING_RECORD_FAILED:
			return { ...state, error: action.payload, isGettingExistingRecord: false};


		case records.GET_SYS_OPTIONS_STARTED:
			return { ...state, isGettingSystemOptions: true,};

		case records.GET_SYS_OPTIONS_SUCCESSFUL:
			return { ...state, systemOptions: action.payload, isGettingSystemOptions: false};

		case records.GET_SYS_OPTIONS_FAILED:
			return { ...state, error: action.payload, isGettingSystemOptions: false};


		case records.FILE_MVT_STATS_STARTED:
			return { ...state, isGettingMvtStats: true,};

		case records.FILE_MVT_STATS_SUCCESSFUL:
			return { ...state, fileMovtStats: action.payload, isGettingMvtStats: false};

		case records.FILE_MVT_STATS_FAILED:
			return { ...state, error: action.payload, isGettingMvtStats: false};

		default:
			return state;
	}
};
