import React, { Component } from "react";
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Accordion, Card, Spinner } from "react-bootstrap";
import _ from 'lodash';
import { Link } from "react-router-dom";
import Viewer from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

import DetailsBar from '../../../components/details-bar/'
import './document.scss'
import { logoutUser, getRecordAttachment, getAttachedDocuments } from '../../../redux/actions'
import { handleSessionControlLogout, UserProfile } from '../../../config'
import { AuthenticatedSessionControl } from 'react-session-control';
import RequestAccess from "../../../components/request-access";

class LegalDocumentPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      showModal: false,
      selected: {}
    };
  }

  componentDidMount = () => {
    const {
      props: {
        records: {
          selectedRecord,
        },
        getRecordAttachment,
        getAttachedDocuments
      }
    } = this


    setTimeout(async () => {
      const id = selectedRecord.Id || selectedRecord.id

      await Promise.all([
        getRecordAttachment(id),
        getAttachedDocuments(id)
      ])

    }, 2000)

  }

  handleClose = () => this.setState({ showModal: false });

  handleShow = (recordId) => {

    this.setState({ showModal: true }); //temp showModal is off

  }


  navigateTo = (screen) => {
    this.props.history.push(`/${screen}`)
  }

  render() {
    const {
      props: {
        legal: {
          attachedDocuments,
          isFetchingAttachment
        },
        records: {
          selectedRecord,
          attachment
        },
      },
      state: {
        showModal,
      }
    } = this


    const attachedDoc = _.get(attachment, 'data', []);

    let pdfRecord = {
      data: []
    }
    if (attachedDoc.length > 0) {
      const fileData = _.get(attachedDoc[0], 'File_data', {})
      const fileBuffer = _.get(fileData, 'data', [])

      const uint8Array = new Uint8Array(fileBuffer);

      pdfRecord = {
        data: uint8Array
      }

    }
    return (
      <div>
        <Container className="legalDocumentPage" fluid>
          {UserProfile.getToken() ? <AuthenticatedSessionControl
            inactivityTimeout={90}
            modalInactivityTimeout={30}
            storageTokenKey="accessToken"
            onLogout={handleSessionControlLogout}
            title={'Inactivity Alert'}
          /> : null}
          <Row className="dashboard">
            <Col xs={12} md={3} className="sideBar">
              {/* <header>
                    <h4>DCIC EDMS</h4>
                  </header> */}
              <br />
              {selectedRecord !== {} ?
                <DetailsBar data={selectedRecord} /> :
                <div></div>
              }

              <Accordion defaultActiveKey="0">
                <Card>
                  <Accordion.Toggle as={"div"} eventKey="0">
                    {isFetchingAttachment?
                        <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />:<></>}
                      {' '} See Attached Document
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    {
                      attachedDocuments.length > 0 ?
                        <ol className="document-list">
                          {
                            attachedDocuments.map((item, index) => {
                              return (
                                <li key={index}><Link to={'/#'}>{item.requested_file} :: {item.attached_file_id}</Link></li>
                              )
                            })
                          }
                        </ol>
                        :
                        <p><Link to={'/#'}>{isFetchingAttachment?'':'No more attached file'}</Link></p>
                    }
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <div className='actionBtn'>
                <Button variant="secondary" size="sm" onClick={() => this.navigateTo('legal')}>
                  Back
                </Button>{' '}
                <Button variant="warning" size="sm" onClick={() => this.handleShow({})}>
                  Request Access
                </Button>
              </div>
            </Col>
            <Col xs={12} md={9} className="mainSection">
              <main>
                {
                  pdfRecord.data.length > 0 ?
                    <div
                      style={{
                        height: '90%',
                        width: '100%'
                      }}
                    >
                      <Viewer fileUrl={pdfRecord.data} />
                    </div>
                    : <></>
                }

              </main>
            </Col>
          </Row>
        </Container>

        <RequestAccess show={showModal} handleClose={this.handleClose} recordId={selectedRecord['id']}/>

      </div>
    );
  }
}

const mapStateToProps = ({ user, records, legal }) => {
  return {
    user,
    records,
    legal
  };
};

export default connect(
  mapStateToProps,
  { logoutUser, getRecordAttachment, getAttachedDocuments }
)(LegalDocumentPage);