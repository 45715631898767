import React, { Component } from "react";
import { connect } from 'react-redux';
import {Container, Row, Col, Button, Modal, ListGroup} from "react-bootstrap";
import Moment from 'moment';
import './record_manager.scss'
import {getUserRecords, getSelectedRecord, updateSearchField, logoutUser, getSystemOptions} from  '../../redux/actions'
import { AuthenticatedSessionControl } from  'react-session-control';
import { UserProfile, handleSessionControlLogout } from '../../config';
import RecordTrackerReport from "./components/reports"
import CheckoutRecordModal from '../../components/checkout-record'
import CheckinRecordModal from '../../components/checkin-record'
import UserBar from '../../components/user-bar'

class RecordManagerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      showModal: false,
      showCheckoutRecordModal: false,
      showCheckinRecordModal: false,
      selected: {},
      activeList: "report"
    };
  }

  componentWillMount =()=>{

    if(!UserProfile.getToken() ){
      window.location = '/'
    }

  }

  formatDate = (date) => {
    Moment.locale('en');
    return Moment(date).format('d-MMM-YYYY');
  }

  handleClose = () => this.setState({showModal: false, showCheckoutRecordModal:false, showCheckinRecordModal:false});

  handleShow = (recordId) => {
    const {records: { data }} = this.props;

    // var dt = data.filter((record) => record.Id === recordId);
    var dt = data.filter((record) => record.id === recordId);
    
   this.setState({selected: dt[0], showModal: false}); //temp showModal is off

    setTimeout(this.handleRecordDetails, 600);
  }

  handleDisplayShow = () => {
    this.setState({showCheckoutRecordModal: true})
  }

  handleCheckinDisplay = () => {
    this.setState({showCheckinRecordModal: true})
  }

  handleRecordDetails = () => {
    const { selected } = this.state;

    if(this.props.getSelectedRecord(selected)) {
      // window.location = '/results'
      this.props.history.push('/results')
    }

  }

  componentDidMount(){
    this.props.getSystemOptions()
  }

  render() {
    const {
      records: {  systemOptions },
    } = this.props

    const  {
      showModal,
      selected,
    } = this.state
    
    const inactivityTimeout = Array.isArray(systemOptions) ? 
        systemOptions.filter(item => item.option_name ==='inactivityTimeout')[0]: undefined
    const modalInactivityTimeout = Array.isArray(systemOptions) ?
        systemOptions.filter(item => item.option_name ==='modalInactivityTimeout')[0]:undefined

    return (
      <div>
      <Container className="recordsPage" fluid>
      { UserProfile.getToken()?<AuthenticatedSessionControl
                inactivityTimeout={inactivityTimeout?Number(inactivityTimeout.option_values):360}
                modalInactivityTimeout={modalInactivityTimeout?Number(modalInactivityTimeout.option_values):60}
                storageTokenKey="accessToken"
                onLogout={handleSessionControlLogout}
                title={'Inactivity Alert'}
            />:null }
          { UserProfile.getToken()?
              <Row className="search">
                    <Col xs={12} md={3} className="sideBar">
                      <UserBar title={"MENU"} />
                      
                        <ListGroup defaultActiveKey={0}>
                          <ListGroup.Item action variant="secondary" eventKey={0}>
                            Home
                          </ListGroup.Item>
                          <ListGroup.Item action variant="secondary"  eventKey={1} onClick={() => {this.handleDisplayShow()}}>
                            Checkout a file
                          </ListGroup.Item>
                          <ListGroup.Item action variant="secondary"  eventKey={2} onClick={() => {this.handleCheckinDisplay()}}>
                            Check-in a file
                          </ListGroup.Item>
                        </ListGroup>
                      <br />
                      <div className='actionBtn'>
                        {/* <Button variant="secondary" size="sm" onClick={logoutUser}>
                            close
                        </Button>{' '} */}
                        <Button variant="secondary" size="sm" href={'/dashboard'}>
                            Back
                        </Button>{' '}    
                        <Button variant="warning" size="sm">
                          Logout
                        </Button> {' '}    
                    </div>
                    </Col>
                    <Col xs={12} md={9} className="mainSection">
                        {
                          <RecordTrackerReport />
                        }
                    </Col>
                </Row>:
              <div></div>
          }
      </Container>
      <Modal show={showModal} onHide={this.handleClose} size="md" centered={true}>
        <Modal.Header closeButton>
        <Modal.Title className='modalTitle'>{selected._Name} <small>{'<Record>'}</small></Modal.Title>
        </Modal.Header>
        <Modal.Body>Click on <b>Open Details</b> to view a details record of this individual </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose} size="sm">
            Close
          </Button>
          <Button variant="primary" onClick={this.handleRecordDetails} size="sm">
            Open Details
          </Button>
        </Modal.Footer>
      </Modal>
      <CheckoutRecordModal show={this.state.showCheckoutRecordModal} handleClose={this.handleClose}/>
      <CheckinRecordModal show={this.state.showCheckinRecordModal} handleClose={this.handleClose}/>
      </div>
    )
  }
}


const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ getUserRecords, getSelectedRecord, updateSearchField, logoutUser, getSystemOptions }
)(RecordManagerPage);