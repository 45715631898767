import React, { Component } from "react";
import { connect } from 'react-redux';
import { Col, Form, Button, Spinner} from "react-bootstrap";
import './general-settings.scss'
import {updateGeneralSettings, getSystemOptions} from  '../../../../redux/actions';
import { UserProfile } from '../../../../config';

import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'


class GeneralSettingsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      showModal: false,
      selected: {},
      activeList: "report",
      agencies: [],
      recordState: [],
      userRoles: ['admin','user'],
      inactivityTimeout: 360,
      modalInactivityTimeout: 360,
    };
  }

  componentWillMount =()=>{

    if(!UserProfile.getToken() ){
      window.location = '/'
    }
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;


    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    event.stopPropagation();

    this.setState({validated: true});
    if (form.checkValidity()) {
      const optionData = {
        agencies: this.state.agencies,
        recordState: this.state.recordState,
        userRoles: this.state.userRoles,
        inactivityTimeout: this.state.inactivityTimeout,
        modalInactivityTimeout: this.state.modalInactivityTimeout,
      }
      this.props.updateGeneralSettings(optionData).then(async res =>{
        if(res){
          await this.props.getSystemOptions()

          alert("System update has been successful")
        }else{
          alert("System update has failed, please try again")
        }
      })

    }
    

  };
  
  handleChange = (event) => {
    if(event.target.name !== undefined){
      this.setState({ [event.target.name]: event.target.value });
    
    }
  }

  handleTags = (tag) => {
    
    const tagName = tag.name

    this.setState({[tagName]: tag.values})


    // if(newTags.includes(tag.values[tag.values.length - 1]) == false && tag.values.length > 0 ){

    //   newTags.push(tag.values[tag.values.length - 1])

    //   this.setState({[tagName]: newTags})

    // }else{
    //   this.setState({[tagName]: tag.values})
    // }

  }

  async componentDidMount(){
    const {
      records: {
        systemOptions
      }
    } = this.props


    await this.props.getSystemOptions()

    let defaultOption = []

    const optionsWithArrays = ['agencies','recordState','userRoles']

    if(systemOptions.length > 0){

      systemOptions.forEach(option => {
        
        if(optionsWithArrays.includes(option.option_name)){

          if(option.option_values !=='') defaultOption = option.option_values.split(',')
        
          if(defaultOption.length > 0) {
            this.setState({[option.option_name]: defaultOption})
          }

        }else{

          this.setState({[option.option_name]: option.option_values})

        }

      })

    }

  }
  
  render() {
    const {
      user: {
        isUpdatingGeneralSettings
      }
    } = this.props

    const agencyTags = this.state.agencies?this.state.agencies:[]
    const recordStateTags = this.state.recordState?this.state.recordState:[]
    const userRoleTags = this.state.userRoles?this.state.userRoles:[]

    return (
        <div className="mainSettings">
          <div className="dashTitle">General system settings</div>
          <main>
            {/* form here */}

            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit} style={{width:"80%",textAlign:'left'}}>

                <Form.Row>
                    <Form.Group as={Col} controlId="formGridFName">
                      <Form.Label>Set inactivity timeout</Form.Label>
                          <Form.Control as="select" custom  name='inactivityTimeout' value={this.state.inactivityTimeout} onChange={this.handleChange}>
                            <option value={"120"}>2 minutes</option>
                            <option value={"180"}>3 minutes</option>
                            <option value={"240"}>4 minutes</option>
                            <option value={"300"}>5 minutes</option>
                            <option value={"360"}>6 minutes</option>
                            <option value={"420"}>7 minutes</option>
                            <option value={"480"}>8 minutes</option>
                            <option value={"540"}>9 minutes</option>
                            <option value={"600"}>10 minutes</option>
                          </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridFName">
                      <Form.Label>Set modal inactivity timeout</Form.Label>
                      <Form.Control as="select" custom  name='modalInactivityTimeout' value={this.state.modalInactivityTimeout}  onChange={this.handleChange}>
                        <option value={"60"}>1 minutes</option>
                        <option value={"120"}>2 minutes</option>
                        <option value={"180"}>3 minutes</option>
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridRoles">
                      <Form.Label>Add system user roles <div><small>(type and tap on space button to add another option)</small></div></Form.Label>
                      <div className='input-group'>
                        <InputTags values={userRoleTags} name="userRoles" onTags={(value) => this.handleTags(value)} />
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridAgencies">
                      <Form.Label>Add record requesting agencies
                        <div><small>(These are the agencies, ministries, organs which request for records)</small></div>
                      </Form.Label>
                      <div className='input-group'>
                        <InputTags values={agencyTags} name="agencies" onTags={(value) => this.handleTags(value)} />
                      </div>
                    </Form.Group>

                  </Form.Row>

                  <Form.Group controlId="formGridRecordState">
                    <Form.Label>Add record state options
                    <div><small>(This defines the state of the record when returned like damaged, missing pages)</small></div>
                    </Form.Label>
                    <div className='input-group'>
                      <InputTags values={recordStateTags} name="recordState" onTags={(value) => this.handleTags(value)} />
                    </div>
                  </Form.Group>
                  
                  <Button type="submit" variant="primary" disabled="">
                    
                  {
                      isUpdatingGeneralSettings?<Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />:<></>
                    }
                    {isUpdatingGeneralSettings?'Please wait..':'Save'}
                    
                    </Button>

              </Form>
          </main>
        </div>
    )
  }
}


const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ updateGeneralSettings, getSystemOptions }
)(GeneralSettingsSection);