export const user = {
  LOGIN_STARTED: "login_started",
  LOGIN_SUCCESSFUL: "login_successful",
  LOGIN_FAILED: "login_failed",
  LOGOUT: 'logout',
  GET_USERS_STARTED:'GET_USERS_STARTED',
  GET_USERS_SUCCESSFUL:'GET_USERS_SUCCESSFUL',
  GET_USERS_FAILED:'GET_USERS_FAILED',
  CREATE_USER_STARTED: 'CREATE_USER_STARTED',
  CREATE_USER_SUCCESSFUL: 'CREATE_USER_SUCCESSFUL',
  CREATE_USER_FAILED: 'CREATE_USER_FAILED',

  UPDATE_PASSWORD_STARTED:'UPDATE_PASSWORD_STARTED',
  UPDATE_PASSWORD_SUCCESSFUL:'UPDATE_PASSWORD_SUCCESSFUL',
  UPDATE_PASSWORD_FAILED:'UPDATE_PASSWORD_FAILED',

  RESET_PASSWORD_STARTED:'RESET_PASSWORD_STARTED',
  RESET_PASSWORD_SUCCESSFUL:'RESET_PASSWORD_SUCCESSFUL',
  RESET_PASSWORD_FAILED:'RESET_PASSWORD_FAILED',

  UPDATE_NOTIF_STARTED: "UPDATE_NOTIF_STARTED",
  UPDATE_NOTIF_SUCCESSFUL: "UPDATE_NOTIF_SUCCESSFUL",
  UPDATE_NOTIF_FAILED: "UPDATE_NOTIF_FAILED",

  UPDATE_GENERAL_OPTION_STARTED:"UPDATE_GENERAL_OPTION_STARTED",
  UPDATE_GENERAL_OPTION_SUCCESSFUL:"UPDATE_GENERAL_OPTION_SUCCESSFUL",
  UPDATE_GENERAL_OPTION_FAILED:"UPDATE_GENERAL_OPTION_FAILED"
};

export const records = {
  FETCH_RECORDS_STARTED: 'fetch_records_started',
  FETCH_RECORDS_SUCCESSFUL: 'fetch_records_successful',
  FETCH_RECORDS_FAILED: 'fetch_records_failed',
  SET_SELECTED_RECORD_STARTED: 'set_selected_recorded_started',
  SET_SELECTED_RECORD_SUCCESSFUL: 'set_selected_recorded_successful',
  SET_SELECTED_RECORD_FAILED: 'set_selected_recorded_failed',
  FETCH_ATTACHMENT_STARTED: 'fetch_attachment_started',
  FETCH_ATTACHMENT_SUCCESSFUL: 'fetch_attachment_successful',
  FETCH_ATTACHMENT_FAILED: 'fetch_attachment_failed',
  UPDATE_SEACH_FIELDS_STARTED: 'update_search_field_started',
  UPDATE_SEACH_FIELDS_SUCCESSFUL: 'update_search_field_successful',
  UPDATE_SEACH_FIELDS_FAILED:  'update_search_field_failed',
  FETCH_LOGS_STARTED: 'FETCH_LOGS_STARTED',
  FETCH_LOGS_SUCCESSFUL: 'FETCH_LOGS_SUCCESSFUL',
  FETCH_LOGS_FAILED: 'FETCH_LOGS_FAILED',

  CHECKIN_RECORD_STARTED: 'CHECKIN_RECORD_STARTED',
  CHECKIN_RECORD_SUCCESSFUL: 'CHECKIN_RECORD_SUCCESSFUL',
  CHECKIN_RECORD_FAILED: 'CHECKIN_RECORD_FAILED',

  CHECKOUT_RECORD_STARTED: 'CHECKOUT_RECORD_STARTED',
  CHECKOUT_RECORD_SUCCESSFUL: 'CHECKOUT_RECORD_SUCCESSFUL',
  CHECKOUT_RECORD_FAILED: 'CHECKOUT_RECORD_FAILED',

  CHECK_EXISTING_RECORD_STARTED: 'CHECK_EXISTING_RECORD_STARTED',
  CHECK_EXISTING_RECORD_SUCCESSFUL: 'CHECK_EXISTING_RECORD_SUCCESSFUL',
  CHECK_EXISTING_RECORD_FAILED: 'CHECK_EXISTING_RECORD_FAILED',

  GET_SYS_OPTIONS_STARTED: 'GET_SYS_OPTIONS_STARTED',
  GET_SYS_OPTIONS_SUCCESSFUL: 'GET_SYS_OPTIONS_SUCCESSFUL',
  GET_SYS_OPTIONS_FAILED: 'GET_SYS_OPTIONS_FAILED',

  FILE_MVT_STATS_STARTED:"FILE_MVT_STATS_STARTED",
  FILE_MVT_STATS_SUCCESSFUL:"FILE_MVT_STATS_SUCCESSFUL",
  FILE_MVT_STATS_FAILED:"FILE_MVT_STATS_FAILED",

}


export const legal = {

  REQUEST_FILE_STARTED:"REQUEST_FILE_STARTED",
  REQUEST_FILE_SUCCESSFUL:"REQUEST_FILE_SUCCESSFUL",
  REQUEST_FILE_FAILED:"REQUEST_FILE_FAILED",

  GET_REQUESTED_FILES_STARTED:"GET_REQUESTED_FILES_STARTED",
  GET_REQUESTED_FILES_SUCCESSFUL:"GET_REQUESTED_FILES_SUCCESSFUL",
  GET_REQUESTED_FILES_FAILED:"GET_REQUESTED_FILES_FAILED",

  APPROVE_REQUESTED_FILES_STARTED:"APPROVE_REQUESTED_FILES_STARTED",
  APPROVE_REQUESTED_FILES_SUCCESSFUL:"APPROVE_REQUESTED_FILES_SUCCESSFUL",
  APPROVE_REQUESTED_FILES_FAILED:"APPROVE_REQUESTED_FILES_FAILED",

  DENY_REQUESTED_FILES_STARTED:"DENY_REQUESTED_FILES_STARTED",
  DENY_REQUESTED_FILES_SUCCESSFUL:"DENY_REQUESTED_FILES_SUCCESSFUL",
  DENY_REQUESTED_FILES_FAILED:"DENY_REQUESTED_FILES_FAILED",

  GET_ATTACHED_FILE_STARTED:"GET_ATTACHED_FILE_STARTED",
  GET_ATTACHED_FILE_SUCCESSFUL:"GET_ATTACHED_FILE_SUCCESSFUL",
  GET_ATTACHED_FILE_FAILED:"GET_ATTACHED_FILE_FAILED",

  ASSIGN_FILE_ACCESS_STARTED:"ASSIGN_FILE_ACCESS_STARTED",
  ASSIGN_FILE_ACCESS_SUCCESSFUL:"ASSIGN_FILE_ACCESS_SUCCESSFUL",
  ASSIGN_FILE_ACCESS_FAILED:"ASSIGN_FILE_ACCESS_FAILED",

  VERIFY_REQUESTED_FILES_STARTED:"VERIFY_REQUESTED_FILES_STARTED",
  VERIFY_REQUESTED_FILES_SUCCESSFUL:"VERIFY_REQUESTED_FILES_SUCCESSFUL",
  VERIFY_REQUESTED_FILES_FAILED:"VERIFY_REQUESTED_FILES_FAILED",

}