import React, { Component } from "react";
import { connect } from 'react-redux';
import { Col, Spinner, Form, Button} from "react-bootstrap";
import './notifications.scss'
import {updateNotifications, getSystemOptions} from  '../../../../redux/actions'
import { UserProfile } from '../../../../config';

class NotificationSettingsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sysNotifEmailAddress: "",
      notifEmailAddress: "",
    };
  }

  componentWillMount =()=>{

    if(!UserProfile.getToken() ){
      window.location = '/'
    }

    // this.props.getUserRecords({});
  }

  componentDidMount(){
    const {
      records: {
        systemOptions
      }
    } = this.props

    const optionsWithArrays = ['sysNotifEmailAddress','notifEmailAddress']

    if(systemOptions.length > 0){

      systemOptions.forEach(option => {
        
        if(optionsWithArrays.includes(option.option_name)){
        
            this.setState({[option.option_name]: option.option_values})
        }

      })

    }

  }

  handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {

      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    event.stopPropagation();

    this.setState({validated: true});
    if (form.checkValidity()) {

      const notifOptions = {
        sysNotifEmailAddress: this.state.sysNotifEmailAddress,
        notifEmailAddress: this.state.notifEmailAddress
      }

      // setTimeout(, 3000)

      this.props.updateNotifications(notifOptions).then(res => {
        if(res){
            alert("update has been successful")
        }else{
          alert("Please try again later, update failed")
        }
      })


    }
    

  };
      
  handleChange = (event) => {
    if(event.target.name !== undefined){
      this.setState({ [event.target.name]: event.target.value });
        }
  }

  componentWillMount(){
    this.props.getSystemOptions()
  }

  render() {
    const {
      user: { isUpdatingNotifOptions },
    } = this.props
    
    return (
        <div className="mainSettings">
          <div className="dashTitle">Notification Settings</div>
          <main>
            {/* form here */}

            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit} style={{width:"80%",textAlign:'left'}}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridFName">
                      <Form.Label>Add email addresses to receive record related notifications
                        <div><small>(Enter multiple emails separated by comma)</small></div>
                      </Form.Label>
                      <Form.Control as="textarea" rows={2} name='notifEmailAddress' placeholder="" 
                        onChange={this.handleChange} value={this.state.notifEmailAddress}/>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridLName">
                      <Form.Label>Add email addresses to receive system related notifications
                        <div><small>(Enter multiple emails separated by comma)</small></div>
                      </Form.Label>
                      <Form.Control as="textarea" rows={2} name='sysNotifEmailAddress' onChange={this.handleChange} 
                        value={this.state.sysNotifEmailAddress}/>
                    </Form.Group>
                  </Form.Row>

                  <Button type="submit" variant="primary" disabled="">
                  {
                      isUpdatingNotifOptions?<Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />:<></>
                    }
                    {isUpdatingNotifOptions?'Please wait..':'Save'}
                    </Button>

              </Form>
          </main>
        </div>
    )
  }
}


const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ updateNotifications, getSystemOptions}
)(NotificationSettingsSection);