import React  from "react";
import './manageUser.scss'
import PropTypes from "prop-types";
import CustomUsersTable,  { SelectColumnFilter, StatusPill }  from "../table/users_table";

const ManageUser = (props) => {


  function ServiceClassTable(prop) {

    const columns = [
        {
          Header: "User ID",
          accessor: "id"
        },
        {
          Header: "Username",
          accessor: "Username"
        },
        {
          Header: "Email",
          accessor: "Email",
          Filter: SelectColumnFilter,  // new
          filter: 'includes',  // new
        },
        {
          Header: "Phone Number",
          accessor: "Phone_No_1",
        },
        {
          Header: "Department",
          accessor: "Department",
        },
        {
          Header: "Role",
          accessor: "user_role",
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: StatusPill,  // new

        }
      ]
  
  
    return (
      <CustomUsersTable columns={columns} data={prop.data} />
    )
  }

    return (
      <div className={'manageUsern'}>
        <h4 className="font-bold text-left">Manage Users</h4>
        {/* <Row style={{marginBottom:'0.5rem'}}>
          <Col></Col>
          <Col xs={6}></Col>
          <Col style={{display:'flex',flexDirection:'row-reverse'}}><Button onClick={() => props.handleAddUser()}>Add User</Button></Col>
        </Row> */}
        <div className="border">
          <div className="w-full">
            {/* <BootstrapTable keyField='id' data={ props.users } columns={ columns } filter={ filterFactory() } pagination={ paginationFactory() } /> */}
            <ServiceClassTable data={props.users} />
          </div>
        </div>
      </div>
    );
  }
  ManageUser.defaultProps = {
    show: false
  };
  
  ManageUser.propTypes = {
    show: PropTypes.bool,
  };
  
export default ManageUser;