import React, { Component } from "react";
import { connect } from 'react-redux';
import {Container, Row, Col, Button} from "react-bootstrap";
import _ from 'lodash';

import Viewer from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

import DetailsBar from '../../components/details-bar/'
import './results.scss'
import {logoutUser, getRecordAttachment} from  '../../redux/actions'
import {handleSessionControlLogout, UserProfile} from '../../config'
import { AuthenticatedSessionControl } from  'react-session-control';
import RequestAccessListModal from "../../components/request-access-list";

class ResultsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      showModal: false,
      selected: {}
    };
  }

  handleShow (){
    this.setState({
      showModal: true
    })
  }

  handleClose = () => this.setState({showModal: false})
  
  componentDidMount =()=>{
    const {
      props: {
        records: { 
          selectedRecord,
        },
        getRecordAttachment
      }
    } = this

    // if(!sessionStorage.getItem('user_LoggedIn') ){
    //   window.location = '/'
    // }
    setTimeout(()=>{
      const id = selectedRecord.Id  || selectedRecord.id 
      getRecordAttachment(id);
    },2000)

  }
  navigateTo = (screen) => {
    this.props.history.push(`/${screen}`)
  }

  render() {
    const {
      props: {
        records: { 
          selectedRecord,
          attachment
        },
      },
      state:{
        showModal
      }
    } = this

    const attachedDoc = _.get(attachment, 'data', []);

    let pdfRecord = {
      data: []
    }
    if(attachedDoc.length > 0 ) {
      const fileData = _.get(attachedDoc[0], 'File_data', {})
      const fileBuffer = _.get(fileData, 'data', [])
      
      const uint8Array  = new Uint8Array(fileBuffer);

      pdfRecord = {
        data: uint8Array
      }
      
    }
     return (
        <Container className="resultsPage" fluid>
          { UserProfile.getToken()?<AuthenticatedSessionControl
                inactivityTimeout={90}
                modalInactivityTimeout={30}
                storageTokenKey="accessToken"
                onLogout={handleSessionControlLogout}
                title={'Inactivity Alert'}
            />:null }
            <Row className="dashboard">
                <Col xs={12} md={3} className="sideBar">
                  {/* <header>
                    <h4>DCIC EDMS</h4>
                  </header> */}
                  <br />
                  { selectedRecord !== {} ? 
                      <DetailsBar data={selectedRecord}/>:
                      <div></div>
                    }
                  <div className='actionBtn'>
                    <Button variant="warning" size="sm" onClick={() => this.navigateTo('search')}>
                      Back
                    </Button> {' '}         
                    <Button variant="primary" size="sm" onClick={()=>{this.handleShow()} }>
                        Assign Access
                    </Button>
                  </div>
                </Col>
                <Col xs={12} md={9} className="mainSection">
                  <main>
                      {
                      pdfRecord.data.length > 0?
                        <div
                            style={{
                                height: '90%',
                                width: '100%'
                            }}
                        >
                            <Viewer fileUrl={pdfRecord.data}/>
                        </div>
                        :<></>
                      }

                  </main>
                </Col>
            </Row>
            <RequestAccessListModal show={showModal} record={selectedRecord} handleClose={this.handleClose}/>
        </Container>
    );
  }
}

const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ logoutUser, getRecordAttachment }
)(ResultsPage);