import React, { Component } from "react";
import { connect } from 'react-redux';
import Moment from 'moment';

import './reports.scss'
import { UserProfile } from '../../../../config';
import DashboardCard from "../../../../components/dashboard-card"
import QueryRecordTracker from "../../../../components/query_record_tracker"
import { BsCalendar, BsBoxArrowInUpRight, BsBoxArrowInDownLeft} from 'react-icons/bs';
import {getUserRecords, getSelectedRecord, updateSearchField, logoutUser, getFileMvtStats} from '../../../../redux/actions'

class RecordTrackerReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      showModal: false,
      selected: {},
      activeList: "report"
    };
  }

  componentWillMount =()=>{

    if(!UserProfile.getToken() ){
      window.location = '/'
    }

    // this.props.getUserRecords({});
  }

  formatDate = (date) => {
    Moment.locale('en');
    return Moment(date).format('d-MMM-YYYY');
  }


userDash = [
  {title:'Files checked-out',icon:BsBoxArrowInUpRight, stat: `0`, IconColor:'#f4952e',  action: ()=>this.props.getFileMvtStats('checked-out')},
  {title:'Files checked-in',icon:BsBoxArrowInDownLeft, stat: `0`, IconColor: '#33693a', action: ()=>this.props.getFileMvtStats('checked-in')},
  {title:'Files overdue',icon:BsCalendar, stat: `0`, IconColor1: '#459efb',IconColor:"#e91e63", action: ()=>this.props.getFileMvtStats('overdue')},
]

  render() {

    return (
        <div className="mainReport">
          {/* <header>
            <img src={logo} alt='logo'/>
            <h4>Directorate of Citizenship and Immigration Control</h4>
          </header> */}
          <section className={"statSection"}>
            <div className="dashTitle">Last 30 days activity</div>
            <DashboardCard stats={this.userDash} colCount={4} />
          </section>
          <main>
          <QueryRecordTracker />
          </main>
        </div>
    )
  }
}


const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ getUserRecords, getSelectedRecord, updateSearchField, logoutUser, getFileMvtStats }
)(RecordTrackerReport);