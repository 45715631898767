import axios from 'axios';

import {user} from '../../constants/types'
import {API, UserProfile} from '../../config'

export const loginUser = ({userName, userPass}) => {
    return dispatch => {

        dispatch({ type: user.LOGIN_STARTED });

        return axios
            .post(`${API.baseURL}/login`, 
                { Username:userName, Password:userPass }
            )
            .then(response => {
                
                console.log(" >>> >> >> ", response)

                UserProfile.setToken(response.data.accessToken);
                UserProfile.setRole(response.data.User['user_role']);
                UserProfile.setAccessType(response.data.User.accessType);
                UserProfile.setDepartment(response.data.User.Department);
                UserProfile.setName(response.data.User.First_Name);
                UserProfile.setStatus(response.data.User.Status);
                UserProfile.setUserId(response.data.User.Id)
                UserProfile.setUsername(response.data.User.Username);

                dispatch({ type: user.LOGIN_SUCCESSFUL, payload:response.data });

                return{
                    data: response.data,
                    status: true
                }
            })
            .catch(error => {
                dispatch({ type: user.LOGIN_FAILED, payload: error.response });
                return{
                    status: false
                }
            });
    };
};

export const getUsers = () => {
    return dispatch => {

        dispatch({ type: user.GET_USERS_STARTED });

        return axios
            .post(`${API.baseURL}/get-users`,{},{headers:{token: UserProfile.getToken(), user: UserProfile.getUsername()}})
            .then(response => {
                dispatch({ type: user.GET_USERS_SUCCESSFUL, payload:response.data.data });
            })
            .catch(error => {
                dispatch({ type: user.GET_USERS_FAILED, payload: error.response });
            });
    };
}

export const createUser = (userData) => {
    return dispatch => {

        dispatch({ type: user.CREATE_USER_STARTED});
        return axios
            .post(`${API.baseURL}/create-user`, userData,{headers:{token: UserProfile.getToken(), user: UserProfile.getUsername()}})
            .then(response => {
                dispatch({ type: user.CREATE_USER_SUCCESSFUL, payload:response.data });
            })
            .catch(error => {
                dispatch({ type: user.CREATE_USER_FAILED, payload: error.response });
            });
    };
}

export const updateUserPassword = (userData) => {
    return dispatch => {

        dispatch({ type: user.UPDATE_PASSWORD_STARTED});
        return axios
            .post(`${API.baseURL}/update-user-password`, userData,{headers:{token: UserProfile.getToken(), user: UserProfile.getUsername()}})
            .then(response => {
                dispatch({ type: user.UPDATE_PASSWORD_SUCCESSFUL, payload:response.data });
            })
            .catch(error => {
                dispatch({ type: user.UPDATE_PASSWORD_FAILED, payload: error.response });
            });
    };
}

export const resetUserPassword = (userData) => {
    return dispatch => {

        dispatch({ type: user.RESET_PASSWORD_STARTED});
        return axios
            .post(`${API.baseURL}/reset-password`, userData,{headers:{token: UserProfile.getToken(), user: UserProfile.getUsername()}})
            .then(response => {
                dispatch({ type: user.RESET_PASSWORD_SUCCESSFUL, payload:response.data });
                return true
            })
            .catch(error => {
                dispatch({ type: user.RESET_PASSWORD_FAILED, payload: error.response });
                return false
            });
    };
}

export const updateNotifications = (notificationData) => {
    return dispatch => {

        dispatch({ type: user.UPDATE_NOTIF_STARTED});
        return axios
            .post(`${API.baseURL}/update-notif-option`, notificationData,{headers:{token: UserProfile.getToken()}})
            .then(response => {
                dispatch({ type: user.UPDATE_NOTIF_SUCCESSFUL, payload:response.data });
                return true
            })
            .catch(error => {
                dispatch({ type: user.UPDATE_NOTIF_FAILED, payload: error.response });

                return false
            });
    };
}

export const updateGeneralSettings = (settingsData) => {
    return dispatch => {

        dispatch({ type: user.UPDATE_GENERAL_OPTION_STARTED});
        return axios
            .post(`${API.baseURL}/update-general-option`, settingsData,{headers:{token: UserProfile.getToken()}})
            .then(response => {
                dispatch({ type: user.UPDATE_GENERAL_OPTION_SUCCESSFUL, payload:response.data });
                return true;
            })
            .catch(error => {
                dispatch({ type: user.UPDATE_GENERAL_OPTION_FAILED, payload: error.response });
                return false;
            });
    };
}

export const logoutUser = () => {
    return dispatch => {
        sessionStorage.removeItem('user_LoggedIn');
        UserProfile.deleleSession()
        window.location = '/'
        dispatch({ type: user.LOGOUT });
        
    };
};

export const isSessionValid = () => {
    if(sessionStorage.getItem('accessToken')){
        return dispatch => {
            sessionStorage.removeItem('user_LoggedIn');
            window.location = '/'
            dispatch({ type: user.LOGOUT });
            
        };
    }
}

export default {
    loginUser
};