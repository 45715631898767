// src/Table.js
// https://www.samuelliedtke.com/blog/react-table-tutorial-part-1/

import React from "react";
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy, usePagination } from 'react-table'  // new
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid'
import { TButton, PageButton } from './shared/Button'
import { SortDownIcon, SortUpIcon, SortIcon } from './shared/Icons'
import { classNames } from "./shared/Utils";

function CustomUsersTable({ columns, data, source }) {


    const {
        getTableProps, getTableBodyProps, headerGroups, prepareRow,

        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,

        state, // new
        preGlobalFilteredRows, // new
        setGlobalFilter, // new
      } = useTable({
        columns,
        data,
      },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
      )


  const changeAccountStatus = (selectedRow, status="disable") => {
    const vv = {}

    if(status==="disable"){
        const response = window.confirm("Are you sure you want to disable this account ?");

        if(response){
            alert("Processing...")
        }

    }

    if(status==="enable"){
        const response = window.confirm("Are you sure you want to enable this account ?");

        if(response){
            alert("Processing...")
        }

    }

    selectedRow.map((item, value) => {
        return vv[item.column.id] = item.value
    })


  }

  // Render the UI for your table
  return (
    <>

        <div className="flex gap-x-1 text-sm">
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
            />

            {   
                headerGroups.map((headerGroup) =>
                headerGroup.headers.map((column) =>
                column.Filter ? (
                    <div key={column.id}>
                        {column.render("Filter")}
                    </div>
                ) : null
                )
            )}
        </div>

        <div className="mt-2 flex flex-col">
            <div className="-my-2 overflow-x-auto -mx-1 sm:-mx-1 lg:-mx-1">
                <div className="py-2 align-middle inline-block min-w-full sm:px-0 lg:px-0">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table {...getTableProps()} className="min-w-full divide-y divide-gray-200 mt-4">
                    <thead className="bg-green-800 text-black">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th
                            scope="col"
                            className="group bg-gray-500 px-6 py-2 text-left text-xs font-medium text-white uppercase tracking-wider"
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                            <div className="flex items-center justify-between">
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>
                                {column.isSorted
                                ? column.isSortedDesc
                                    ? <SortDownIcon className="w-4 h-4 text-gray-500" />
                                    : <SortUpIcon className="w-4 h-4 text-gray-500" />
                                : (
                                    <SortIcon className="w-4 h-4 text-gray-500 opacity-0 group-hover:opacity-100" />
                                )}
                            </span>
                            </div>
                        </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody
                    {...getTableBodyProps()}
                    className="bg-white divide-y divide-gray-200 text-sm"
                    >
                    {page.map((row, i) => {  // new
                        prepareRow(row)
                        return (
                        <tr
                            {...row.getRowProps()} className="hover:bg-gray-100 cursor-pointer">
                            {row.cells.map(cell => {
                            return (
                                <td
                                {...cell.getCellProps()}
                                className="px-6 py-2 whitespace-nowrap"
                                >
                                {cell.render('Cell')}
                                </td>
                            )
                            })}
                            <td className="text-xs text-center text-white hover:text-white">
                                { Math.floor(Math.random() * 6) < 3?<div onClick={()=>changeAccountStatus(row.cells, "disable")} className="p-1 bg-red-400 rounded   hover:bg-red-600">Disable</div>
                                        :<div onClick={()=>changeAccountStatus(row.cells, 'enable')} className="t p-1 bg-blue-400 rounded hover:bg-blue-600">Enable</div>
                                }
                            </td>
                        </tr>
                        )
                    })}
                    </tbody>
                </table>
                </div>
            </div>
            </div>
        </div>

        <div className="py-3 flex items-center justify-between">
            <div className="flex-1 flex justify-between sm:hidden">
            <TButton onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</TButton>
            <TButton onClick={() => nextPage()} disabled={!canNextPage}>Next</TButton>
            </div>

            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="flex gap-x-2">
                <span className="text-sm text-gray-700 self-center">
                Page <span className="font-medium">{state.pageIndex + 1}</span> of <span className="font-medium">{pageOptions.length}</span>
                </span>
                <label>
                    <span className="sr-only">Items Per Page</span>
                    <select
                        className="mt-1 block w-full py-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        value={state.pageSize}
                        onChange={(e) => {
                        setPageSize(Number(e.target.value));
                        }}
                    >
                        {[5, 10, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                        ))}
                    </select>
                </label>

            </div>
            <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <PageButton
                    className="rounded-l-md"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                >
                    <span className="sr-only">First</span>
                    <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                </PageButton>
                <PageButton
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </PageButton>
                <PageButton
                    onClick={() => nextPage()}
                    disabled={!canNextPage
                    }>
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </PageButton>
                <PageButton
                    className="rounded-r-md"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                >
                    <span className="sr-only">Last</span>
                    <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                </PageButton>
                </nav>
            </div>
            </div>
        </div>

    </>
  );
}

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
  
    return (
        <label className="flex gap-x-2 items-baseline">
            <span className="text-gray-700">Filter: </span>
            <input
            type="text"
            className="mt-1 block w-full py-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            value={value || ""}
            onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            placeholder={`${count} records...`}
            />
        </label>
    )
  }

export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <label className="flex ml-1 gap-x-2 items-baseline">
        <span className="text-gray-700">{render("Header")}: </span>
        <select
            className="mt-1 block w-full py-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            name={id}
            id={id}
            value={filterValue}
            onChange={e => {
            setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
            <option key={i} value={option}>
                {option}
            </option>
            ))}
        </select>
        </label>
    );
}

export function StatusPill({ value }) {
    const status = value ? value.toLowerCase() : "unknown";
  
    return (
      <span
        className={classNames(
          "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
          status.startsWith("active") ? "bg-green-100 text-green-700" : null,
          status.startsWith("inactive") ? "bg-yellow-100 text-yellow-700" : null,
          status.startsWith("expired") ? "bg-red-100 text-red-700" : null
        )}
      >
        {status}
      </span>
    );
  }

export default CustomUsersTable;