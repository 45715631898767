import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link, Route } from "react-router-dom";
import {Container, Row, Col, Card, Button} from "react-bootstrap";
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import RegisterUserModal from '../../components/register-user'
import ManageUser from '../../components/manage-user'
import QueryLogs from '../../components/query-logs'
import './users.scss'
import {getUsers} from '../../redux/actions'
import {userManagementConf, handleSessionControlLogout, UserProfile} from '../../config'
import { AuthenticatedSessionControl } from  'react-session-control';

class UsersPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showRegisterModal: false
    };

  }

  handleClose = () => this.setState({showRegisterModal: false});
  handleShow = () => this.setState({showRegisterModal: true})

  componentDidMount =()=>{
    this.props.getUsers()
  }

  navigateTo = (screen) => {
    this.props.history.push(`/${screen}`)
  }

  display = (link) => {
    if(link === 'add-user') {
      this.handleShow()
    }else{
      this.props.history.push(`/users/${link}`)
    }
  }

  render() {
    const {
      props: {
        user: {
          users
        }
      }
    } = this

     return (
       <>
        <Container className="usersPage" fluid>
          { UserProfile.getToken()?<AuthenticatedSessionControl
              inactivityTimeout={90}
              modalInactivityTimeout={30}
              storageTokenKey="accessToken"
              onLogout={handleSessionControlLogout}
              title={'Inactivity Alert'}
          />:null }
            <Row className="dashboard">
                <Col xs={12} md={2} className="sideBar">
                  <br />
                  {
                    userManagementConf.map((item, idx) => (
                      <Link onClick={()=>this.display(item.link)}>
                        <Card
                          bg={item.color.toLowerCase()}
                          key={idx}
                          text={item.color.toLowerCase() === 'light' ? 'dark' : 'white'}
                          style={{ width: '100%' }}
                          className="mb-2 menu-item"
                        >
                          <Card.Header>{item.title}</Card.Header>
                        </Card>
                      </Link>
                    ))
                  }

                  <div className='actionBtn'>
                        <Button variant="secondary" size="sm" href={'/dashboard'}>
                          Back
                        </Button>{' '}
                        <Button variant="warning" size="sm">
                          Logout
                        </Button> {' '}       
                    </div>
                    
                </Col>
                <Col xs={12} md={10} className="mainSection">
                  <main style={{flexDirection:'column'}} className="w-full">
                    <Route path="/users/logs" component={QueryLogs} exact/>
                    <Route path='/users' render={(props) => <ManageUser handleAddUser={this.handleShow} users={users} {...props}/>} exact/>
                  </main>
                </Col>
            </Row>
        </Container>
        <RegisterUserModal show={this.state.showRegisterModal} handleClose={this.handleClose}/>

      </>
    );
  }
}

const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ getUsers }
)(UsersPage);