import React, { Component } from "react";
import { connect } from 'react-redux';
import { Row, Col, Form} from "react-bootstrap";
import moment from 'moment'

import {updateSearchField} from  '../../redux/actions'
import './wildcardSearch.scss'
import {Countries } from '../../config'

class WildcardSearch extends Component {

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    const { target } = event;
    const { name, value } = target;
    
    this.props.updateSearchField({name, value}, true)
  }

  render() {
    const now = new Date();   
    
    const minDate = moment(`${(now.getFullYear() - 90)}-01-01`).format( 'YYYY-MM-DD')
    const maxDate = moment(`${now.getFullYear()}-${(now.getMonth()+1)}-${now.getDate()}`).format('YYYY-MM-DD') 
    return (
        <Row className="wildcardSearch">
            <Col md={12} >
                <h4>Wildcard Search</h4>
                <Form.Group>
                    <Form.Row>
                        <Form.Label column="sm" lg={3}>
                            File No.
                        </Form.Label>
                        <Col>
                            <Form.Control size="sm" type="text" name='File_No' placeholder="Enter file number"  onChange={this.onChange} />
                        </Col>
                    </Form.Row>
                </Form.Group>
                <Form.Group>
                    <Form.Row>
                        <Form.Label column="sm" lg={3}>
                            First Name
                        </Form.Label>
                        <Col>
                            <Form.Control size="sm" type="text" name='Name1' placeholder="Enter name" pattern="[^'\x22]+"  onChange={this.onChange} />
                        </Col>
                    </Form.Row>
                </Form.Group>
                <Form.Group>
                    <Form.Row>
                        <Form.Label column="sm" lg={3}>
                            OtherName
                        </Form.Label>
                        <Col>
                            <Form.Control size="sm" type="text" name='Name2' placeholder="Enter other name" pattern="[^'\x22]+"  onChange={this.onChange} />
                        </Col>
                    </Form.Row>
                </Form.Group>
                <Form.Group>
                    <Form.Row>
                        <Form.Label column="sm" lg={3}>
                            D.O.B
                        </Form.Label>
                        <Col>
                            <Form.Control size="sm" type="date" name='DOB' min={minDate} max={maxDate} placeholder="Enter Date of birth"  onChange={this.onChange} />
                        </Col>
                    </Form.Row>
                </Form.Group>
                <Form.Group>
                    <Form.Row>
                        <Form.Label column="sm" lg={3}>
                            P.O.B
                        </Form.Label>
                        <Col>
                            <Form.Control size="sm" type="text" name='POB' placeholder="Enter place of birth"  onChange={this.onChange} />
                        </Col>
                    </Form.Row>
                </Form.Group>
                <Form.Group>
                    <Form.Row>
                        <Form.Label column="sm" lg={3}>
                            Nationality
                        </Form.Label>
                        <Col>
                            <Form.Control as="select" size="sm" name='Nationality' custom onChange={this.onChange} >
                                <option value=''>Select Nationality</option>
                                {Countries.length > 0 ? Countries.map(country => (
                                    <option value={country.name} key={country.code}>{country.name}</option> 
                                )): <option value='uganda'>Uganda</option> }
                                
                            </Form.Control>
                        </Col>
                    </Form.Row>
                </Form.Group>
                <Form.Group>
                    <Form.Row>
                        <Form.Label column="sm" lg={3}>
                            Company
                        </Form.Label>
                        <Col>
                            <Form.Control size="sm" type="text" name='Company' placeholder=""  onChange={this.onChange}  />
                        </Col>
                    </Form.Row>
                </Form.Group>
            </Col>
        </Row>
    );
  }
}

const mapStateToProps = ({  records }) => {
	return {
    records
	};
};

export default connect(
	mapStateToProps,
	{ updateSearchField }
)(WildcardSearch);