import React, { Component } from "react";
import { Form, Button, Modal, Col, Alert, Container, Jumbotron} from "react-bootstrap";
import { connect } from 'react-redux';
import './checkin.scss'
import {createUserField} from '../../config'
import {getUserRecords,checkExistingRecord, checkinRecord} from  '../../redux/actions'

class CheckinRecordModal extends Component  {

    constructor(props) {
      super(props);
      this.state = {
        setShow: false,
        showModal: false,
        selected: "",
        validated:false,
        isSearchingRecord:false,
        isUpatingRecord:false,
        step: 1,
        recordStateOptions:[]
      };
    }
    FormField = createUserField

    handleSubmit = (event) => {
      const form = event.currentTarget;
      const {
        records: {
          isGettingExistingRecord,
        }
      } = this.props

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      event.preventDefault();
      event.stopPropagation();

      this.setState({validated: true});
      if (form.checkValidity()) {


        //select correct file number
        if(this.state.step === 2) {
          this.FormField['fileNumber'] = this.state.selected
          
          setTimeout(this.props.checkinRecord(this.FormField).then(res => {

            if(!isGettingExistingRecord){

              // form.reset()
              // this.props.handleClose()
              setTimeout(()=>{
                this.setState({step: 3})
              },200)
            }

          }), 1000)


        }

        //check if the file exists with the entered file number
        if(this.state.step === 1) {
          
          this.props.checkExistingRecord(this.FormField).then(res => {

            if(res && res.results.data) {

              console.log("res -> ", res)
              this.setState({
                selected: res.results.data?.[0]['record_name']
              })
              setTimeout(()=>{
                this.setState({step: 2})
              },300)
            }else {
              alert("No record found corresponding to the entered file number")
            }

          })
        }


      }
      

    };
    

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });

        const { target } = event;
        const { name, value } = target;
        this.FormField[name] = value
    }

    closeModal = () => {
      this.props.handleClose()
      this.setState({step: 1, selected: ""})
    }

    componentDidMount(){
      const {

        records: {
          systemOptions
        }

      } = this.props

      let recordStateOptions = systemOptions.filter(item => item.option_name ==='recordState')[0]
      
      if(recordStateOptions !== undefined){

        if(recordStateOptions.option_values !=='') recordStateOptions = recordStateOptions.option_values.split(',')
        
        if(recordStateOptions.length > 0) {
          this.setState({"recordStateOptions": recordStateOptions})
        }

      }

    }

    render(){

      const {
        user: {
          isCreatingUser
        },
        records: {
          isGettingExistingRecord,
          isCheckingRecord,
        }
  
      } = this.props


      return (
          <Modal
          show={this.props.show}
          onHide={this.closeModal}
          backdrop="static"
          size={'lg'}
          keyboard={false}
          scrollable={true}
          centered={true}
          className={"checkoutModal"}
          
        >
          <Modal.Header closeButton>
            <Modal.Title>Check-in a file {this.state.selected?'<'+this.state.selected+'>':null}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                {
                  this.state.step === 1?
                  <>
                  <Form.Group controlId="formGridAddress">
                    <Form.Label>Enter the file number</Form.Label>
                    <Form.Control name="fileNumber" placeholder="File number" required onChange={this.handleChange}
                      disabled={false}/>
                  </Form.Group>
                  <Button type="submit" variant="primary" disabled={isGettingExistingRecord} loading={isGettingExistingRecord}>{isGettingExistingRecord?'Please wait..':'Search record'}</Button>
                  </>:null
                }
                {
                  this.state.step === 2?
                  <>
                    <Form.Group as={Col} controlId="formGridRole">
                      <Form.Label>Returned record state</Form.Label>
                      <Form.Control as="select" name="status" defaultValue="Choose..." onChange={this.handleChange}>
                          <option>Choose appropriate state...</option>
                          {/* <option value='1'>Good</option>
                          <option value='2'>Damaged</option>
                          <option value='3'>Missing pages</option> */}
                          {
                            this.state.recordStateOptions.map((item, i) => {
                              return <option value={item}>{item}</option>
                            })
                          }
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridComment">
                        <Form.Label>Comment<small className="required"> *(its important you add comments to support the option above)</small></Form.Label>
                        <Form.Control as="textarea" rows={2} name="comment_on_checkin" placeholder="" required onChange={this.handleChange} maxLength={255}/>
                    </Form.Group>
                  <Button type="submit" variant="primary" disabled={isCheckingRecord} loading={isCheckingRecord}>{isCheckingRecord?'Please wait..':'Save'}</Button>
                  </>:null
                }

                {
                this.state.step === 3?<>
                  <Jumbotron fluid>
                    <Container>
                      <h1>Successful</h1>
                      <p>
                        You have successfully checked-in this record back
                      </p>
                    </Container>
                  </Jumbotron>
                </>:null
                }
              </Form>
          </Modal.Body>
          <Modal.Footer>
            {
            isCreatingUser?<Alert variant={'success'}>
              Record has been updated successfully 
            </Alert>:null
            }
            <Button variant="secondary" size="sm" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }

const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ getUserRecords, checkExistingRecord, checkinRecord }
)(CheckinRecordModal);