import React, { Component } from "react";
import { Alert, Button, Modal,Spinner } from "react-bootstrap";
import { connect } from 'react-redux';
import './requestAccessList.scss'
import { getRequestedDocuments, approveRequestedDocument, assignDocumentToRequested, verifyRequestedDocument, getSelectedRecord } from  '../../redux/actions'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { UserProfile } from '../../config'
import {  withRouter } from 'react-router-dom'

import DenyAccessModal from "../deny-access-modal";

class RequestAccessListModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      showDenyModal: false,
      selected: {},
      validated: false,
      selectedItems:[],
      requestedDocuments: []
    };
  }

  handleRecordDetails = (selected) => {

    if(this.props.getSelectedRecord(selected)) {

      this.props.history.push('/legal/document')

    }

  }

  columns = [
        {
    dataField: 'Id',
    text: '#'
  },
    {
    dataField: 'created_on',
    text: 'Date',
    formatter: (cell, row, rowIndex, extraData) => {
      return  (
        <div key={row.Id}>
          {row.created_on.split('T')[0]}
        </div>
      )
    }
  },
  {
    dataField: 'status',
    text: 'Status'
  },
  {
    dataField: 'requested_file',
    text: 'Requested File',
  },
  {
    dataField: 'requested_by',
    text: 'Created By'
  },
  {
    dataField: 'File_No',
    text: 'Parent File',
    formatter: (cell, row, rowIndex, extraData) => {

      return  (
        <div onClick={()=> {this.handleRecordDetails(row)}} key={row.Id} className="link">
          {row.File_No}
        </div>
      )
    }
  },
  {
    dataField: 'Notes',
    text: 'Reason'
  }
  ];

  approveRequestHandler = async () => {
    const {
      props: {
        approveRequestedDocument
      },
      state: {
        selectedItems
      }
    } = this

    await approveRequestedDocument(selectedItems)

  }

  verifyRequestHandler = async () => {
    const {
      props: {
        verifyRequestedDocument
      },
      state: {
        selectedItems
      }
    } = this

    await verifyRequestedDocument(selectedItems)

  }

  denyRequestHandler= async () => {
    this.handleShow()
  }

  assignRequestHandler = async () => {
    const {
      props: {
        record,
        assignDocumentToRequested,
      },
      state: {
        selectedItems
      }
    } = this 
    const request = selectedItems[0]
    const attachment_requested = record
    await assignDocumentToRequested(request, attachment_requested)
  
  }

  selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    onSelect: (row, isSelect, rowIndex, e) => {

      if(isSelect){
        const newList = this.state.selectedItems || []
        console.log("selected list", row)
        newList.push({...row})
        this.setState({
          selectedItems: newList
        })
      }else{      
        
        const selectedItems = this.state.selectedItems || []
        
        const newList = selectedItems.filter((item) => item.Id !== row.Id)

        this.setState({
          selectedItems: newList
        })
      }

    },
    onSelectAll: (isSelect, rows, e) => {

    },
    style: { background: '#eee' }

  };

  handleClose = () => this.setState({ showDenyModal: false });

  handleShow = () => {

    this.setState({ showDenyModal: true }); //temp showModal is off

  }


  async componentDidMount(){

    const dept = UserProfile.getDepartment()
    const role = UserProfile.getRole()
    const userId = UserProfile.getUserId()
    const res = await this.props.getRequestedDocuments(dept, userId, role)

    this.setState({
      requestedDocuments: res['payload']
    });

  }

  render() {

    const {
      state:{
        selectedItems,
        showDenyModal
      },
      props:{
        legal:{
          isRequestingApproval,
          isVerifyingRequest,
          isDenyingRequest,
          approveResponse,
          verificationResponse,
          isAssigningAccess,
          assignAccessResponse
        }
      }
    } = this

    const isAdminOrSupervisor = UserProfile.getRole() < 3
    const isSupervisor = UserProfile.getRole() == 2

    const requestingDept = selectedItems.length> 0 ?selectedItems[0]['dept_requesting']: undefined
    const approvingDept = selectedItems.length> 0 ?selectedItems[0]['dept_approving'] : undefined


    const isVerifier = isSupervisor && ( requestingDept == UserProfile.getDepartment() )

    const isApprover = isSupervisor && (UserProfile.getDepartment() == approvingDept)

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        backdrop="static"
        size={'xl'}
        keyboard={false}
        scrollable={true}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <small>Document Access Requests</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {
            this.state.requestedDocuments?.length>0?
              <div>
                <div style={{ marginTop: "2px", marginBottom: "4px", fontWeight: "bold" }}>Total List of Requests for documents</div>
                <div style={{ marginBottom: "4px" }}>
                  {(selectedItems.length > 0 && isAdminOrSupervisor )&&
                    <div className="actionBtn">
                      {this.props.record ?
                        <button className={`${(isDenyingRequest || isRequestingApproval || isAssigningAccess) ? 'cursor-not-allowed' : ''} button`}
                          disabled={isAssigningAccess}
                          onClick={() => { this.assignRequestHandler() }}>
                          {
                            isAssigningAccess ? <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            /> : <></>
                          }
                          {' '}{isAssigningAccess ? " processing" : "Assign Access"}

                        </button>
                        :
                        <>
                          {
                            isSupervisor ?
                              <>
                                {/* {isApprover&&
                                <button className={`${(isDenyingRequest || isRequestingApproval) ? 'cursor-not-allowed' : ''} button`}
                                  disabled={isDenyingRequest || isRequestingApproval}
                                  onClick={() => { this.approveRequestHandler() }}>
                                  {
                                    isRequestingApproval ? <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    /> : <></>
                                  }
                                  {isRequestingApproval ? " processing" : "Approve Request"}
                                </button>
                                } */}
                                {isVerifier&&<button className={`${(isDenyingRequest || isVerifyingRequest) ? 'cursor-not-allowed' : ''} button`}
                                  disabled={isDenyingRequest || isVerifyingRequest}
                                  onClick={() => { this.verifyRequestHandler() }}>
                                  {
                                    isVerifyingRequest ? <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    /> : <></>
                                  }
                                  {isVerifyingRequest ? " processing" : "Verify Request"}
                                </button> 
                                }
                                <button className={`${(isDenyingRequest || isRequestingApproval) ? 'cursor-not-allowed' : ''} button`}
                                  disabled={isDenyingRequest || isRequestingApproval}
                                  onClick={() => { this.denyRequestHandler() }}>
                                  {
                                    isDenyingRequest ? <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    /> : <></>
                                  }
                                  {isDenyingRequest ? "processing" : "Deny Request"}

                                </button>
                              </> :
                              <>
                                {/* <button className={`${(isDenyingRequest || isRequestingApproval) ? 'cursor-not-allowed' : ''} button`}
                                  disabled={isDenyingRequest || isRequestingApproval}
                                  onClick={() => { this.approveRequestHandler() }}>
                                  {
                                    isRequestingApproval ? <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    /> : <></>
                                  }
                                  {isRequestingApproval ? " processing" : "Approve Request"}
                                </button> */}
                                <button className={`${(isDenyingRequest || isRequestingApproval) ? 'cursor-not-allowed' : ''} button`}
                                  disabled={isDenyingRequest || isRequestingApproval}
                                  onClick={() => { this.denyRequestHandler() }}>
                                  {
                                    isDenyingRequest ? <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    /> : <></>
                                  }
                                  {isDenyingRequest ? "processing" : "Deny Request"}

                                </button>
                              </>
                          }
                        </>
                      }
                    </div>
                  }
                </div>
                {
                  approveResponse.status<400&&(!isDenyingRequest && !isRequestingApproval)?<Alert variant={'success'}>
                    {approveResponse['message']}
                  </Alert>:null
                  }
                  {
                  approveResponse.status>=400&&(!isDenyingRequest && !isRequestingApproval)?<Alert variant={'warning'}>
                    Something wrong happened, try again later. If error persist contact support team
                  </Alert>:null
                  }
                  {
                  assignAccessResponse.status>=400&&(!isAssigningAccess)?<Alert variant={'warning'}>
                    Something wrong happened, try again later. If error persist contact support team
                  </Alert>:null
                  }
                                    {
                  assignAccessResponse.status<400&&(!isAssigningAccess)?<Alert variant={'success'}>
                    {assignAccessResponse['message']}
                  </Alert>:null
                  }
                  {
                  verificationResponse.status>=400&&(!isVerifyingRequest)?<Alert variant={'warning'}>
                    Something wrong happened, try again later. If error persist contact support team
                  </Alert>:null
                  }
                                    {
                  verificationResponse.status<400&&(!isVerifyingRequest)?<Alert variant={'success'}>
                    {verificationResponse['message']}
                  </Alert>:null
                  }
                <BootstrapTable bootstrap4 keyField='Id'
                  selectRow={this.selectRow}
                  data={this.state.requestedDocuments || []}
                  columns={this.columns} pagination={paginationFactory()} 
                  search={ {
                    searchFormatted: true
                  } }
                  />
              </div>
            :
            <div style={{padding:"10px", textAlign:"center", fontColor:"#555"}}>
              You currently have no requested files
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
        <DenyAccessModal show={showDenyModal} handleClose={this.handleClose} data={selectedItems}/>
      </Modal>
    );
  }
}

const mapStateToProps = ({ user, records, legal }) => {
  return {
    user,
    records,
    legal
  };
};

export default connect(
  mapStateToProps,
  { getRequestedDocuments, approveRequestedDocument, assignDocumentToRequested, verifyRequestedDocument, getSelectedRecord }
)( withRouter(RequestAccessListModal));