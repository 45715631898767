import React  from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Row, Col, Image} from "react-bootstrap";
import './userBar.scss'
import Logo from '../../assets/logo.png'
import {UserProfile} from  '../../config'
import {logoutUser} from  '../../redux/actions'
const UserBar = (props) => {
    return (
        <header className='user-header'>
            <Row>
                <Col xs={12} sm={3}><Image src={Logo} roundedCircle fluid/></Col>
                <Col xs={12} sm={9} className="user-label">
                    <span>{UserProfile.getName()}</span>
                    <Link onClick={() => props.logoutUser()}>Logout</Link>
                </Col>
            </Row>
            <Row className="menu-section">
                <Col xs={12} sm={12} className="menu-label">
                    {props.title}
                </Col>
            </Row>

        </header>
    );
  }
  
  
// export default UserBar;

const mapStateToProps = () => {
	return {
	};
};

export default connect(
	mapStateToProps,
	{ logoutUser }
)(UserBar);