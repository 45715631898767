import React, { Component } from "react";
import { connect } from 'react-redux';
import styled from 'styled-components'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
// A great library for fuzzy filtering/sorting items
import {matchSorter} from 'match-sorter'

// import makeData from './makeData'

import {getFileMvtStats} from  '../../redux/actions'


const Styles = styled.div`
  padding: 1rem;
  padding-top: 0.2rem;
  width: 100%;
  table {
    border-spacing: 0;
    border: 0px solid #888;
    width: 100%;
    text-align:left;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #888;
      border-right: 1px solid #888;
      :last-child {
        border-right: 0;
      }
      :nth-child(3) {
        width: 46%;
      }
    }
    th {
      background: #1a4543;
      text-align: left;
      color: #f1f1f1;
    }
  }


  .logTableTitle{
    margin-top: 1rem;
    text-align: left;
    padding-left: 0;
    color: #1a4543;
  }
`

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Search All Reccords :{' '}
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter // useGlobalFilter!
  )

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  const firstPageRows = rows.slice(0, 10)

  return (
    <>
      <table {...getTableProps()}>
        <thead>
        <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
                background: 'unset',
                color: '#444',
                paddingLeft: 'unset'
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

const columns = [
  {
    Header: 'Date of Collection',
    accessor: 'date_taken',
  },
  {
    Header: 'File Number',
    accessor: 'record_name',
    // Use our custom `fuzzyText` filter on this column
    filter: 'fuzzyText',
  },
  {
    Header: 'File owner',
    accessor: 'record_owner'
  },
  {
    Header: 'Messeger Name',
    accessor: 'contact_name'
  }, 
  {
    Header: 'Authorizing Person',
    accessor: 'authorizing_person'
  }, 
]


class queryRecordTracker extends Component {

  componentDidMount(){
    this.props.getFileMvtStats('overdue'); // '' if u want to display current month logs
  }

  render(){
    const {
      records: {
        fileMovtStats
      }
    } = this.props

    return (
      <Styles>
        <h4 className={'logTableTitle'} >File Movement Tracker</h4>
        <Table columns={columns} data={fileMovtStats} styles={{}}/>
      </Styles>
    )
  }

}

const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ getFileMvtStats }
)(queryRecordTracker);