import React, { Component } from "react";
import { Form, Button, Modal, Col, Alert} from "react-bootstrap";
import { connect } from 'react-redux';
import './registerUser.scss'
import {createUserField} from '../../config'
import {getUserRecords,createUser, logoutUser} from  '../../redux/actions'

class RegisterUserModal extends Component  {

    constructor(props) {
      super(props);
      this.state = {
        setShow: false,
        showModal: false,
        selected: {},
        validated:false,
      };
    }
    FormField = createUserField

    handleSubmit = (event) => {
      const form = event.currentTarget;
      const {
        user: {
          isCreatingUser
        }
      } = this.props

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      event.preventDefault();
      event.stopPropagation();

      this.setState({validated: true});
      if (form.checkValidity()) {
        setTimeout(this.props.createUser(this.FormField), 3000)

          // if(!isCreatingUser){
          //   form.reset()
          //   this.props.handleClose()
          // }
      }
      

    };
    

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });

        const { target } = event;
        const { name, value } = target;
        this.FormField[name] = value
    }

    render(){

      const {
        user: {
          isCreatingUser
        }
      } = this.props

      return (
          <Modal
          show={this.props.show}
          onHide={this.props.handleClose}
          backdrop="static"
          size={'lg'}
          keyboard={false}
          scrollable={true}
          centered={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                  <Form.Row>
                      <Form.Group as={Col} controlId="formGridFName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control type="text" name='FirstName' placeholder="Enter First Name" 
                        required onChange={this.handleChange}/>
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridLName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control type="text" name='LastName'
                        placeholder="Enter Last Name" required onChange={this.handleChange} />
                      </Form.Group>
                  </Form.Row>

                  <Form.Row>
                      <Form.Group as={Col} controlId="formGridPhone">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control type="text" name='Phone1'
                        placeholder="Enter Phone" required  onChange={this.handleChange}/>
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" name="Email"
                        placeholder="Enter email" required onChange={this.handleChange}/>
                      </Form.Group>
                  </Form.Row>

                  <Form.Row>
                      <Form.Group as={Col} controlId="formGridUsername">
                      <Form.Label>Username</Form.Label>
                      <Form.Control type="text" name="Username"
                        placeholder="Enter Username" required onChange={this.handleChange}/>
                      <Form.Control.Feedback type="invalid">
                        Please choose a username.
                      </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" name="Password" placeholder="Password" onChange={this.handleChange}/>
                      </Form.Group>
                  </Form.Row>

                  <Form.Group controlId="formGridAddress">
                      <Form.Label>Address</Form.Label>
                      <Form.Control name="Address" placeholder="1234 Main St" required onChange={this.handleChange}/>
                  </Form.Group>

                  <Form.Row>
                      <Form.Group as={Col} controlId="formGridRole">
                      <Form.Label>User Role</Form.Label>
                      <Form.Control as="select" name="UserRole" defaultValue="Choose..." onChange={this.handleChange}>
                          <option>Choose...</option>
                          <option value='1'>Administrator</option>
                          <option value='2'>Supervisor</option>
                          <option value='3'>Data Clerk</option>
                          <option value='4'>Records Officer</option>
                      </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridDept">
                      <Form.Label>Department</Form.Label>
                      <Form.Control as="select" name="Department" defaultValue="Choose..." onChange={this.handleChange}>
                          <option>Choose...</option>
                          <option value='1'>Immigration</option>
                          <option value='2'>Passport</option>
                          <option value='3'>Admin</option>
                          <option value='4'>Legal</option>
                      </Form.Control>
                      </Form.Group>
                  </Form.Row>
                  <Button type="submit" variant="primary" disabled={isCreatingUser}>{isCreatingUser?'Please wait..':'Create User'}</Button>
              </Form>
          </Modal.Body>
          <Modal.Footer>
            {
            isCreatingUser?<Alert variant={'success'}>
              Account has been created successfully 
            </Alert>:null
            }
            <Button variant="secondary" onClick={this.props.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }

const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ getUserRecords, createUser, logoutUser }
)(RegisterUserModal);