import React, { Component } from "react";
import { connect } from 'react-redux';
import { Row, Col, Form} from "react-bootstrap";

import {updateSearchField} from  '../../redux/actions'
import './specificSearch.scss'

class SpecificSearch extends Component {

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });

        const { target } = event;
        const { name, value } = target;
        this.props.updateSearchField({name, value}, false)
    }
  render() {
    return (
        <Row className="specificSearch">
            <Col md={12} >
                <h4>Specific Search</h4>
                <Form.Group>
                    <Form.Row>
                        <Form.Label column="sm" lg={3}>
                            Search by
                        </Form.Label>
                        <Col>
                            {/* <Form.Control size="sm" type="text" placeholder="Enter file number or NIN"  onChange={this.onChange} /> */}
                            <Form.Control as="select" size="sm" name="specificFilterBy" custom onChange={this.onChange} >
                                <option value='fileNo'>File No</option>
                                <option value='passport'>Passport</option>
                                <option value='phone'>Phone</option>
                                <option value='recordBy'> Record by</option>
                                <option value='email'> Email</option>
                            </Form.Control>
                        </Col>
                    </Form.Row>
                </Form.Group>
                <Form.Group>
                    <Form.Row>
                        <Form.Label column="sm" lg={3}>
                            
                        </Form.Label>
                        <Col>
                            <Form.Control size="sm" type="text" placeholder="Enter value here" name='specificFilterValue' onChange={this.onChange} />
                        </Col>
                    </Form.Row>
                </Form.Group>
            </Col>
        </Row>
    );
  }
}

const mapStateToProps = ({  records }) => {
	return {
    records
	};
};

export default connect(
	mapStateToProps,
	{ updateSearchField }
)(SpecificSearch);