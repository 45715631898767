import React from 'react';
import { Provider } from 'react-redux'
import { Route, Redirect } from "react-router-dom";
import './App.scss';
import LoginPage from './layout/login/';
import DashboardPage from './layout/dashboard/'
import ResultsPage from './layout/results/'
import SearchPage from './layout/search/'
import UsersPage from './layout/users'
import store from './redux/store'
import { Worker } from '@phuocng/react-pdf-viewer';
// import 'react-bootstrap-table2-filter/dist/react-bootstrap-tazble2-filter.min.css';
// import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Row, Col} from "react-bootstrap";
import {UserProfile, API} from './config'

import LegalSearchPage from './layout/legal/search'
import LegalDocumentPage from './layout/legal/document'
import RecordManagerPage from './layout/record_manager/'
import SettingsPage from './layout/settings/'

const ProtectedRoute 
  = ({ isAllowed, ...props }) => 
     isAllowed 
     ? <Route {...props}/> 
     : <Redirect to="/"/>;


const LegalAccessRoute 
     = ({ isAllowed, ...props }) => 
        isAllowed 
        ? <Route {...props}/> 
        : <Redirect to="/"/>;

function App() {
  return (
    <Provider store={store}>
      <Worker
            workerUrl1="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js"
            workerUrl={`${API.baseURL}/pdf-worker`}
        >
        <div className="App">
        <div className='loginPage'>
          <Row className="mainHeader">
            <Col xs={2} md={2} className="mainSection">
              <div>
                {/* <img src={courtOfArm} alt='' /> */}
              </div>
            </Col>
            <Col xs={12} md={8} className="mainSection">
              <header>
                <h4>Directorate of Citizenship and Immigration Control</h4>
                <h5>Ministry of Internal Affairs</h5>
              </header>
            </Col>
            <Col xs={2} md={2} className="mainSection">
              <div>
                {/* <img src={courtOfArm} alt='' /> */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} className="ugandaFlag">
              <div className='color black' />
              <div className='color yellow' />
              <div className='color red' />
            </Col>
          </Row>
          <div>
            <Route path="/" component={LoginPage} exact={true}/>
            <ProtectedRoute path="/results" component={ResultsPage} isAllowed={UserProfile.getToken()}/>
            <ProtectedRoute path="/search" component={SearchPage} isAllowed={UserProfile.getToken()} />
            <ProtectedRoute path="/users" component={UsersPage} isAllowed={UserProfile.getToken()}/>
            <Route path="/dashboard" component={DashboardPage} isAllowed={UserProfile.getToken()}/>
            <ProtectedRoute path="/records" component={RecordManagerPage} isAllowed={UserProfile.getToken()}/>
            <ProtectedRoute path="/settings" component={SettingsPage} isAllowed={UserProfile.getToken()}/>
            <LegalAccessRoute path="/legal" component={LegalSearchPage} isAllowed={UserProfile.getToken()} />
            <LegalAccessRoute path="/legal/document" component={LegalDocumentPage} isAllowed={UserProfile.getToken()} />
          </div>
        </div>
        </div>
      </Worker>
    </Provider>
  );
}

export default App;
