import React, { Component } from "react";
import { Form, Button, Modal, Col, Alert, Spinner} from "react-bootstrap";
import { connect } from 'react-redux';
import './denyAccess.scss'
import {denyRequestedDocument, requestDocumentAccess} from  '../../redux/actions'

class DenyAccessModal extends Component  {

    constructor(props) {
      super(props);
      this.state = {
        setShow: false,
        showModal: false,
        selected: {},
        validated:false,
        denyReason:"",
        documentType:""
      };
    }

    handleSubmit = (event) => {
      const form = event.currentTarget;
      const {
        legal: {
          success
        }
      } = this.props

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      event.preventDefault();
      event.stopPropagation();

      this.setState({validated: true});
      if (form.checkValidity()) {

        const selectItems = this.props.data

        setTimeout(async ()=>{

          await this.props.denyRequestedDocument({
            request: selectItems,
            reason: this.state.denyReason
          })

        },200)

          if(success !== ''){
            // form.reset()
            // this.props.handleClose()
          }
      }
      

    };
    

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    render(){

      const {
        legal: {
          isDenyingRequest,
          success,
          error
        }
      } = this.props

      return (
          <Modal
          show={this.props.show}
          onHide={this.props.handleClose}
          backdrop="static"
          size={'md'}
          keyboard={false}
          scrollable={true}
          centered={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <small>You are about to deny a request</small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                  <Form.Row>
                    <Form.Group as={Col} controlId="denyRequestForm.requestReason">
                      <Form.Label>Reason for denying request</Form.Label>
                      <Form.Control as="textarea" rows={3} name='denyReason' onChange={this.handleChange}/>
                    </Form.Group>
                  </Form.Row>
                  <Button type="submit" variant="primary" disabled={isDenyingRequest}>
                      {
                        isDenyingRequest?<Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />:<></>
                      }
                    {isDenyingRequest?'Please wait..':'Submit'}{isDenyingRequest}
                    </Button>
              </Form>
          </Modal.Body>
          <Modal.Footer>
            {
            success?<Alert variant={'success'}>
              Access denied successfully
            </Alert>:null
            }
            {
            error?<Alert variant={'warning'}>
              Something wrong happened, try again later
            </Alert>:null
            }
            <Button variant="secondary" onClick={this.props.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }

const mapStateToProps = ({ legal }) => {
	return {
    legal
	};
};

export default connect(
	mapStateToProps,
	{ denyRequestedDocument, requestDocumentAccess }
)(DenyAccessModal);