import React  from "react";
import { Row, Col} from "react-bootstrap";
import './detailsBar.scss'
import PropTypes from "prop-types";
import moment from 'moment';

const DetailsBar = ({ data}) => {
    return (
        <Row className="detailsBar">
            <Col md={12} >
                <header className='header main'>
                    <Row>
                        <Col sm={4}>File No:</Col>
                        <Col sm={8}>{data.File_No}</Col>
                    </Row>
                </header>
                <Row>
                    <Col sm={4} className='fieldItem'>Name</Col>
                    <Col sm={8} className='value name'>{data._Name}</Col>
                </Row>
                <Row>
                    <Col sm={4} className='fieldItem'>Nationality</Col>
                    <Col sm={8} className='value'>{data.Nationality}</Col>
                </Row>
                {
                    data.Passport_Num?<Row>
                        <Col sm={4} className='fieldItem'>Passport No.</Col>
                        <Col sm={8} className='value'>{data.Passport_Num}</Col>
                    </Row>:null
                }
                {
                    data.NIN?<Row>
                        <Col sm={4} className='fieldItem'>National ID</Col>
                        <Col sm={8} className='value'>{data.NIN}</Col>
                    </Row>: null
                }
                <Row>
                    <Col sm={4} className='fieldItem'>Date of Birth</Col>
                    <Col sm={8} className='value'>{moment(data.DOB).format('d-MMM-YYYY')}</Col>
                </Row>
                <Row>
                    <Col sm={4} className='fieldItem'>Place of Birth</Col>
                    <Col sm={8} className='value'>{data.POB}</Col>
                </Row>

                <header className='header others'>
                    <Row>
                        <Col sm={8}>Other Details</Col>
                        <Col sm={4}></Col>
                    </Row>
                </header>
                <Row>
                    <Col sm={4} className='fieldItem'>Phone</Col>
                    <Col sm={8} className='value'>{data.Phone === ", "?'None':data.Phone}</Col>
                </Row>
                <Row>
                    <Col sm={4} className='fieldItem'>Email</Col>
                    <Col sm={8} className='value'>{data.email===''?'None':data.email}</Col>
                </Row>
                {
                    data.Company?<Row>
                        <Col sm={4} className='fieldItem'>Company</Col>
                        <Col sm={8} className='value'>{data.Company}</Col>
                    </Row>:null
                }
                {
                    data.Notes?<Row>
                        <Col sm={12} className='fieldItem'>Notes</Col>
                        <Col sm={12} className='value notes'>{data.Notes}</Col>
                    </Row>:null
                }
                <header className='header document'>
                    <Row>
                        {/* <Col sm={4}>Documents</Col>
                        <Col sm={8}></Col> */}
                        <Col sm={12}></Col>
                    </Row>
                </header>
                {/* <Row>
                    
                    <Col sm={12} className='value '>FILENAME.PDF</Col>
                </Row> */}

            </Col>
        </Row>
    );
  }
  DetailsBar.defaultProps = {
    data: {
        Id: 780607,
        File_No: 'IM-0000-0000',
        Location: 'n7xxxxx',
        _Name: 'John Doe',
        DOB: '1954-04-19T21:15:00.000Z',
        POB: 'NAMIREMBE',
        Phone: ' ',
        email: '',
        NIN: '',
        Notes: '',
        Photo: null,
        fathers_name: '',
        mothers_name: '',
        no_of_passports: 1,
        Nationality: 'Uganda',
        Date_of_Birth: '',
        Age: 0,
        UserID: 0,
        Company: 'N'
    }
  };
  
  DetailsBar.propTypes = {
    data: PropTypes.object.isRequired,
  };
  
export default DetailsBar;