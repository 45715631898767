import React, { Component } from "react";
import { connect } from 'react-redux';
import {Container, Row, Col, Button, Modal, Spinner} from "react-bootstrap";
// import Page from 'react-page-loading'
import Moment from 'moment';

import SpecificSearch from "../../components/specific-search";
import WildcardSearch from "../../components/wildcard-seach";
import Table from 'react-bootstrap/Table'

import './search.scss'
import {getUserRecords, getSelectedRecord, updateSearchField, logoutUser} from '../../redux/actions'
import { AuthenticatedSessionControl } from  'react-session-control';
import { UserProfile, handleSessionControlLogout } from '../../config';


class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setShow: false,
      showModal: false,
      selected: {}
    };
  }

  componentWillMount =()=>{

    if(!UserProfile.getToken() ){
      window.location = '/'
    }

    // this.props.getUserRecords({});
  }

  formatDate = (date) => {
    Moment.locale('en');
    return Moment(date).format('d-MMM-YYYY');
  }

  handleClose = () => this.setState({showModal: false});

  handleShow = (recordId) => {
    const {records: { data }} = this.props;

    // var dt = data.filter((record) => record.Id === recordId);
    var dt = data.filter((record) => record.id === recordId);
    
   this.setState({selected: dt[0], showModal: false}); //temp showModal is off

    setTimeout(this.handleRecordDetails, 600);
  }

  handleRecordDetails = () => {
    const { selected } = this.state;

    if(this.props.getSelectedRecord(selected)) {
      // window.location = '/results'
      const userDept = window.sessionStorage.getItem('accessType')
      const gotoLink = userDept==='legal'?'/legal/document':'/results'
      this.props.history.push(gotoLink)
    }

  }

  handleSearchResults = () => {
    // const { 
    //   records: {
    //     searchFields
    //   }
    // } = this.props

    // const searchType  = _.get(searchFields, 'isWildSearch', false) === true?'wild':'specific';

    this.props.getUserRecords({});

  }

  render() {
    const {
      records: { data, isFetchingRecords }
    } = this.props

    const  {
      showModal,
      selected
    } = this.state
    
    return (
      <div>
      <Container className="searchPage" fluid>
      { UserProfile.getToken()?<AuthenticatedSessionControl
                inactivityTimeout={90}
                modalInactivityTimeout={30}
                storageTokenKey="accessToken"
                onLogout={handleSessionControlLogout}
                title={'Inactivity Alert'}
            />:null }
        {/* <Page loader={"bar"} color={"#A9A9A9"} size={6}>
        </Page> */}
          { UserProfile.getToken()?
              <Row className="search">
                    <Col xs={12} md={3} className="sideBar">
                      {/* <header>
                        <h4>DCIC EDMS</h4>
                      </header> */}
                      <br />
                      <WildcardSearch />
                      <SpecificSearch />
                      <div className='actionBtn'>
                        {/* <Button variant="secondary" size="sm" onClick={logoutUser}>
                            close
                        </Button>{' '} */}
                        <Button variant="secondary" size="sm" href={'/dashboard'}>
                            Back
                        </Button>{' '}
                        <Button variant="warning" size="sm">
                          clear
                        </Button> {' '}       
                        <Button variant="primary" size="sm" onClick={this.handleSearchResults} disabled={isFetchingRecords}>
                        {
                          isFetchingRecords?<Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />:<></>
                        }
                        {isFetchingRecords?' loading...': 'Search'}

                      </Button>
                    </div>
                    </Col>
                    <Col xs={12} md={9} className="mainSection">
                      {/* <header>
                        <img src={logo} alt='logo'/>
                        <h4>Directorate of Citizenship and Immigration Control</h4>
                      </header> */}
                      <main>
                      <Table responsive size='sm'>
                        <thead>
                            <tr>
                            <th>File Number</th>
                            <th>Location</th>
                            <th>Name</th>
                            <th>Date of Birth</th>
                            <th>P.O.B</th>
                            <th>NIN</th>
                            <th>Nationality</th>
                            {/* <th>Age</th> */}
                            <th>Company</th>
                            </tr>
                        </thead>
                        <tbody hover='true'>
                            {(!isFetchingRecords && data.length > 0) ? data.map(record => (
                              <tr key={record.id} onClick={() => this.handleShow(record.id)} >
                                <td>{record.File_No}</td>
                                <td>{record.Location}</td>
                                <td>{record._Name}</td>
                                <td>{this.formatDate(record.DOB)}</td>
                                <td>{record.POB}</td>
                                <td>{record.NIN}</td>
                                <td>{record.Nationality}</td>
                                {/* <td>{record.Age}</td> */}
                                <td>{record.Company}</td>
                              </tr>
                            )): <tr><td className='noResults'>{'No results returned'}</td></tr> }
                        </tbody>
                        </Table>
                      </main>
                    </Col>
                </Row>:
              <div></div>
          }
      </Container>
      <Modal show={showModal} onHide={this.handleClose} size="md" centered={true}>
        <Modal.Header closeButton>
        <Modal.Title className='modalTitle'>{selected._Name} <small>{'<Record>'}</small></Modal.Title>
        </Modal.Header>
        <Modal.Body>Click on <b>Open Details</b> to view a details record of this individual </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose} size="sm">
            Close
          </Button>
          <Button variant="primary" onClick={this.handleRecordDetails} size="sm">
            Open Details
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    )
  }
}


const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ getUserRecords, getSelectedRecord, updateSearchField, logoutUser }
)(SearchPage);