import React, { Component } from "react";
import { Form, Button, Modal, Col, Alert, Popover, OverlayTrigger, Jumbotron, Container} from "react-bootstrap";
import { connect } from 'react-redux';
import './checkout.scss'
import {createUserField} from '../../config'
import {checkUserDuplicates,checkoutRecord, logoutUser} from  '../../redux/actions'

class CheckoutRecordModal extends Component  {

    constructor(props) {
      super(props);
      this.state = {
        setShow: false,
        showModal: false,
        selected: {},
        validated:false,
        step: 1,
        step_one_error: false,
        duplicates: [],
        agencyOptions: [],
        isDocumentAttached: false,
        showNoAttachmentMsg: false
      };
    }
    FormField = createUserField

    handleSubmit = (event) => {
      const form = event.currentTarget;

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      event.preventDefault();
      event.stopPropagation();

      this.setState({validated: true});
      if (form.checkValidity()) {

        //create new record
        if(this.state.step === 3){
          this.FormField['record_name'] = this.state.selected.File_No
          this.FormField['record_owner'] = this.state.selected._Name
          setTimeout(this.props.checkoutRecord(this.FormField).then(res => {

            this.setState({showNoAttachmentMsg: false})

            if(res){

              this.setState({step: 5})

            }else{
              // the transaction failed
              this.setState({step_one_error: true})
              // alert("The request to save this record has failed")
            }

          }), 3000)

        }

        //select correct file number
        if(this.state.step === 2) {
          this.setState({step: 3})
        }

        //check if the file exists with the entered file number
        if(this.state.step === 1) {
          
          this.props.checkUserDuplicates().then(res => {
            if(res && this.props.records.data.length>0){
              this.setState({duplicates: this.props.records.data})

              setTimeout(()=>{
                this.setState({step: 2})
              }, 400)
              
            }
            else{
              
              setTimeout(() => {
                this.setState({step: 4})
              }, 2000)
            }

          })



        }


      }
      

    };
    
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });

        const { target } = event;
        const { name, value } = target;
        this.FormField[name] = value
        
        if(name === "returnUsers"){
          this.handleSelected(value)
        }

        if(name === "file_attachment" && value !==""){
          this.setState({isDocumentAttached: true})
        }
    }

    hideNoAttachedMsg = () => {
      this.setState({showNoAttachmentMsg: false})
    }


    showNoAttachedMsg = () => {
      this.setState({showNoAttachmentMsg: true})
    }

    handleSelected = (id) => {

      const selectedT = this.state.duplicates.find(element => element.Id === id)

      this.setState({selected: selectedT})

    }

    closeModal = () => {
      this.props.handleClose();
      this.setState({step: 1})
    }

    popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Attention !!!</Popover.Title>
        <Popover.Content>
          Are you sure you want to <strong>proceed </strong> without attaching it?
          <div>
           <Button type="button" variant="secondary" size="sm" onClick={this.handleSubmit}>Yes, continue</Button>{' '}
           <Button type="button" variant="primary" size="sm" onClick={()=>this.hideNoAttachedMsg()}>No, let me attach</Button>
          </div>
        </Popover.Content>
      </Popover>
    );

    componentDidMount(){
      const {

        records: {
          systemOptions
        }

      } = this.props

      let agencyOptions = systemOptions.filter(item => item.option_name ==='agencies')[0]
      
      if(agencyOptions !== undefined){

        if(agencyOptions.option_values !=='') agencyOptions = agencyOptions.option_values.split(',')
        
        if(agencyOptions.length > 0) {
          this.setState({"agencyOptions": agencyOptions})
        }

      }

    }

    render(){

      const {
        user: {
          isCreatingUser
        },
        records: {
          isFetchingRecords,
          isCheckingoutRecord
        }

      } = this.props

      const {
        isDocumentAttached,
        showNoAttachmentMsg
      } = this.state

      return (
          <Modal
          show={this.props.show}
          onHide={this.closeModal}
          backdrop="static"
          size={'lg'}
          keyboard={false}
          scrollable={true}
          centered={true}
          className={"checkoutModal"}
          
        >
          <Modal.Header closeButton>
            <Modal.Title>Checkout a file</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                {
                  this.state.step === 1?
                  <>
                  <Form.Group controlId="formGridAddress">
                    <Form.Label>Enter the file number</Form.Label>
                    <Form.Control name="fileNumber" placeholder="File number" required onChange={this.handleChange}
                      disabled={false}/>
                  </Form.Group>
                  <Button type="submit" variant="primary" disabled={isFetchingRecords} loading={isFetchingRecords}>{isFetchingRecords?'Please wait..':'Search record'}</Button>
                  </>:null
                }
                {
                  this.state.step === 2?
                  <>
                    <Form.Group as={Col} controlId="formGridRole">
                      <Form.Label>Select file name</Form.Label>
                      <Form.Control as="select" name="returnUsers" defaultValue="Choose..." onChange={this.handleChange}>
                          <option>Choose correct file name...</option>
                          {
                            this.state.duplicates.map((element, i) => {
                              return <option value={element.Id} onClick={() => this.handleSelected(element.Id)}>{element._Name}</option>
                            })
                          }
                      </Form.Control>
                    </Form.Group>
                  <Button type="submit" variant="primary" disabled={isCreatingUser}>{isCreatingUser?'Please wait..':'Continue'}</Button>
                  </>:null
                }
                {
                  this.state.step === 3?<>
                  <Form.Row>
                    <Form.Group  as={Col} controlId="formGridAddress">
                      <Form.Label>File number</Form.Label>
                      <Form.Control name="record_name" placeholder="File number" required onChange={this.handleChange}
                        disabled={true} value={this.state.selected.File_No}/>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPhone">
                      <Form.Label>File name</Form.Label>
                      <Form.Control type="text" name='record_owner'
                        placeholder="" required  onChange={this.handleChange} disabled={true} value={this.state.selected._Name}/>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formGridAgency"  as={Col}>
                      <Form.Label>Requesting agency</Form.Label>
                      <Form.Control as="select" name="requesting_agency" defaultValue="Choose..." onChange={this.handleChange}>
                          <option value="0">Choose requesting agency...</option>
                          {
                            this.state.agencyOptions.map((item, i) => {
                              return <option value={item}>{item}</option>
                            })
                          }
                      </Form.Control>
                    </Form.Group>

                      <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Authorizing Person</Form.Label>
                      <Form.Control type="text" name="authorizing_person"
                        placeholder="" required onChange={this.handleChange} maxLength={25}/>
                      </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridMessageName">
                      <Form.Label>Collecting officer name</Form.Label>
                      <Form.Control type="text" name='contact_name' placeholder="" 
                        required onChange={this.handleChange} maxLength={40}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridMessageContact">
                      <Form.Label>Collecting officer contact (07XXXXXXXX)</Form.Label>
                      <Form.Control type="text" name='contact_phone'
                        placeholder="" required onChange={this.handleChange} maxLength={10}/>
                    </Form.Group>
                  </Form.Row>

                  <Form.Group controlId="formGridAuthReason">
                    <Form.Label>Reason of authorization</Form.Label>
                    <Form.Control as="textarea" rows={2} maxLength={255} name="authorizing_reason" placeholder="" required onChange={this.handleChange}/>
                  </Form.Group>

                  <Form.Group controlId="formGridAuthFile">
                    <Form.Label>Attach authorizing document <small className="required">(*higly recommended)</small></Form.Label>
                    <Form.Control type="file" name="file_attachment" placeholder="" required onChange={this.handleChange} accept="pdf"/>
                  </Form.Group>
                 
                 {
                   isDocumentAttached?
                  <Button type="submit" variant="primary" disabled={isCheckingoutRecord} loading={isCheckingoutRecord}>{isCheckingoutRecord?'Please wait..':'Save'}</Button>

                  :<OverlayTrigger placement="right" overlay={this.popover} show={showNoAttachmentMsg} >
                    <Button type="button" variant="primary" onClick={()=>this.showNoAttachedMsg()} disabled={isCheckingoutRecord}loading={isCheckingoutRecord}>{isCheckingoutRecord?'Please wait..':'Proceed'}</Button>
                  </OverlayTrigger>
                 }
                </>:null
                }

               {
                this.state.step === 4?<>
                  <Jumbotron fluid>
                    <Container>
                      <h1>Not found</h1>
                      <p>
                        This record is not yet  digitized, please <strong>digitized</strong> this document and then check it out again here
                      </p>
                      <p>
                        <Button variant="primary">Click here</Button> to digitize the record
                      </p>
                    </Container>
                  </Jumbotron>
                </>:null
                }
                {
                this.state.step === 5?<>
                  <Jumbotron fluid>
                    <Container>
                      <h1>Successful</h1>
                      <p>
                        You have successfully recorded this record movement
                      </p>
                    </Container>
                  </Jumbotron>
                </>:null
                }
              </Form>
          </Modal.Body>
          <Modal.Footer>
            {
            this.state.step_one_error?<Alert variant={'danger'}>
              Something wrong happened, please try again
            </Alert>:null
            }
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }

const mapStateToProps = ({ user, records }) => {
	return {
    user,
    records
	};
};

export default connect(
	mapStateToProps,
	{ checkUserDuplicates, checkoutRecord, logoutUser }
)(CheckoutRecordModal);