import React, { Component } from "react";
import { connect } from 'react-redux';
import {Container, Row, Col, Form, Button, Spinner} from "react-bootstrap";
import './login.scss'
import {loginUser} from  '../../redux/actions'
import logo from '../../assets/logo.png';
import { UnauthenticatedSessionControl } from  'react-session-control';

export class LoginPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userPass: '',
      validated : false, 
      setValidated: false
    };
  }
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    const { target } = event;
    const { name, value } = target;

    this.setState({ [name]: value });
  }

  handleSubmit = (event) => {
    const {userName, userPass} = this.state;
    const { loginUser} = this.props

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({validated: true})

    loginUser({userName, userPass})
      .then(res => {
        if(res.status){
          window.location = '/dashboard'
        }else{
          alert("Login has failed, try again later")
        }
      })
  }
  componentDidMount = () => {
    
    if(sessionStorage.getItem('accessToken')){
      // this.props.history.push('/dashboard')
      window.location = '/dashboard'
    }
  
  }

  handleSessionControlLogin= (logoutType) => {
    //Do your logic here...
    alert('Session  started')
  }

  render() {
    const { user : { isLoggingin} } = this.props
    const { validated } = this.state
    
    return (
        <Container className="loginMainContent" fluid>
            <UnauthenticatedSessionControl
                storageTokenKey="accessToken"
                onLogin={this.handleSessionControlLogin}
            />
            <Row className="loginRow">
                <Col md={{ span: 4, offset: 4 }} className='loginSection' >
                  <img src={logo} alt='logo' width='100'/>
                  <br /><br />
                  <h2>DCIC E-ARCHIVES</h2>
                  <Form noValidate validated={validated} autocomplete="disabled" onSubmit={this.handleSubmit}>
                      <Form.Group controlId="formGroupEmail">
                        {/* <Form.Label>Username</Form.Label> */}
                        <Form.Control type="text" placeholder="Enter username" onChange={this.onChange} required name="userName"/>
                        <Form.Control.Feedback type="invalid">
                          Please your username
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="formGroupPassword">
                        {/* <Form.Label>Password</Form.Label> */}
                        <Form.Control type="password" placeholder="Enter password" onChange={this.onChange} required name="userPass" autoComplete={'disabled'}/>
                        <Form.Control.Feedback type="invalid">
                          Please your password
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button type="submit" variant="primary" onClick={this.handleSubmit} disabled={isLoggingin} block>
                        {
                          isLoggingin?<Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />:<></>
                        }
                        {isLoggingin?'Loading': 'Enter'}

                      </Button>
                  </Form>
                </Col>
            </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ user }) => {
  
  return {
		user
	};
};

export default connect(
	mapStateToProps,
	{ loginUser }
)(LoginPage);