
import React from 'react';
import { Row, Col} from "react-bootstrap";
import './styles.scss'


function DashboardCard(props) {

    const data = props.stats.length>0?props.stats:[]

    return (

        <Row justify={'space-around'} gutter={[16, 8]} className='dashboardCard'>
          {
            data.length>0?
              data.map((item, subkey) => {
                return<Col span={ 6 } style={{cursor:"pointer"}}onClick={item.action?()=>item.action():null}>
                  <div className='stats-area'>
                    <strong>{item.stat}</strong>
                    <span>{/*<RiseOutlined /> */} {item.title}</span>
                  </div>
                  <div className='card-icon'>
                    {<item.icon  style={{ fontSize: '2rem', color:item.IconColor}} />  }
                  </div>
                </Col>
              })
            :null
          }
      </Row>
    );
  }

export default DashboardCard;