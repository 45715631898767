import axios from 'axios';
import _ from 'lodash';

import store from '../store'
import {records} from '../../constants/types'
import {API, UserProfile} from '../../config'

export const getUserRecords = (data={}) => {
    return dispatch => {
        dispatch({ type: records.FETCH_RECORDS_STARTED });
        
        const searchFieldsObject = _.get(store.getState(), 'records.searchFields')
        const isWildSearch = _.get(searchFieldsObject, 'isWildSearch', true)
        const APIPATH = isWildSearch ? 'wild-search':'specific-search'
        const searchData = isWildSearch ?  searchFieldsObject :{
            searchSpecificBy: _.get(searchFieldsObject, 'specificFilterBy', true),
            searchSpecificValue: _.get(searchFieldsObject, 'specificFilterValue', true),
        }
        
        return axios
            .post(`${API.baseURL}/${APIPATH}`, searchData,{headers:{token: UserProfile.getToken(), user: UserProfile.getUsername()}} )
            .then(response => {
                dispatch({ type: records.FETCH_RECORDS_SUCCESSFUL, payload:_.get(response, 'data.data') });
                return true
            })
            .catch(error => {
                dispatch({ type: records.FETCH_RECORDS_FAILED, payload: error });
                return false
            });
    };
};
export const checkUserDuplicates = (data={}) => {
    return dispatch => {
        dispatch({ type: records.FETCH_RECORDS_STARTED });
                
        const APIPATH = 'specific-search'
        const searchData = {
            searchSpecificBy: "fileNo",
            searchSpecificValue: data.value,
        }
        
        return axios
            .post(`${API.baseURL}/${APIPATH}`, searchData,{headers:{token: UserProfile.getToken(), user: UserProfile.getUsername()}} )
            .then(response => {
                dispatch({ type: records.FETCH_RECORDS_SUCCESSFUL, payload:_.get(response, 'data.data') });
                return true
            })
            .catch(error => {
                dispatch({ type: records.FETCH_RECORDS_FAILED, payload: error });
                return false
            });
    };
};

export const getSelectedRecord = (record = {}) => {
    return dispatch => {
        dispatch({ type: records.SET_SELECTED_RECORD_STARTED });
        
        if(record !== {} && record !== undefined){
            dispatch({ type: records.SET_SELECTED_RECORD_SUCCESSFUL, payload: record });
            return true        
        }
        else {
            dispatch({ type: records.SET_SELECTED_RECORD_STARTED_FAILED, payload: 'No record selected' });
            return false
        }
    };
}

export const getRecordAttachment = (recordID) => {
    return dispatch => {
        dispatch({ type: records.FETCH_ATTACHMENT_STARTED });

        return axios
            .post(`${API.baseURL}/get-attachment`, { recordID },{headers:{token: UserProfile.getToken(), user: UserProfile.getUsername()}})
            .then(response => {
                
                dispatch({ type: records.FETCH_ATTACHMENT_SUCCESSFUL, payload:_.get(response, 'data') });
                return true
            })
            .catch(error => {
                dispatch({ type: records.FETCH_ATTACHMENT_FAILED, payload: error });
                return false
            });
    };
}

export const updateSearchField = (field = {name:'', value:''}, isWildSearch=true) => {
    return dispatch => {
        dispatch({ type: records.UPDATE_SEACH_FIELDS_STARTED });
        
        if(field !== {} && field !== undefined){
            const currentField = store.getState().records.searchFields
            currentField[field.name] = field.value
            currentField['isWildSearch'] = isWildSearch;
            
            dispatch({ type: records.UPDATE_SEACH_FIELDS_SUCCESSFUL, payload: currentField });
            return true        
        }
        else {
            dispatch({ type: records.UPDATE_SEACH_FIELDS_FAILED, payload: 'No record selected' });
            return false
        }
    };
}

export const getQueryLogs = (logFile) => {
    return dispatch => {
        dispatch({ type: records.FETCH_LOGS_STARTED });
        
        return axios
            .post(`${API.baseURL}/get-logs`, { logFile },{headers:{token: UserProfile.getToken(), user: UserProfile.getUsername()}})
            .then(response => {
                
                dispatch({ type: records.FETCH_LOGS_SUCCESSFUL, payload:_.get(response, 'data.log') });
                return true
            })
            .catch(error => {
                dispatch({ type: records.FETCH_LOGS_FAILED, payload: error });
                return false
            });
    };
}


export const checkinRecord = (data={}) => {
    return dispatch => {
        dispatch({ type: records.CHECKIN_RECORD_STARTED });
    
        const APIPATH = "checkin-record"
        
        return axios
            .post(`${API.baseURL}/${APIPATH}`, data,{headers:{token: UserProfile.getToken(), user: UserProfile.getUsername()}} )
            .then(response => {

                dispatch({ type: records.CHECKIN_RECORD_SUCCESSFUL, payload:_.get(response, 'data.data') });
                return true
            })
            .catch(error => {
                dispatch({ type: records.CHECKIN_RECORD_FAILED, payload: error });
                return false
            });
    };
};

export const checkExistingRecord = (data={}) => {
    return dispatch => {
        dispatch({ type: records.CHECK_EXISTING_RECORD_STARTED });
    
        const APIPATH = "check-existing-record"
        
        return axios
            .post(`${API.baseURL}/${APIPATH}`, data,{headers:{token: UserProfile.getToken(), user: UserProfile.getUsername()}} )
            .then(response => {
                dispatch({ type: records.CHECK_EXISTING_RECORD_SUCCESSFUL, payload:_.get(response, 'data') });
                return { results: _.get(response, 'data')}
            })
            .catch(error => {
                dispatch({ type: records.CHECK_EXISTING_RECORD_FAILED, payload: error });
                return false
            });
    };
};


export const checkoutRecord = (data={}) => {
    return dispatch => {
        dispatch({ type: records.CHECKOUT_RECORD_STARTED });
    
        const APIPATH = "checkout-record"
        
        return axios
            .post(`${API.baseURL}/${APIPATH}`, data,{headers:{token: UserProfile.getToken(), user: UserProfile.getUsername()}} )
            .then(response => {
                dispatch({ type: records.CHECKOUT_RECORD_SUCCESSFUL, payload:_.get(response, 'data') });
                return true
            })
            .catch(error => {
                dispatch({ type: records.CHECKOUT_RECORD_FAILED, payload: error });
                return false
            });
    };
};

export const getSystemOptions = (data={}) => {
    return dispatch => {
        dispatch({ type: records.GET_SYS_OPTIONS_STARTED });
    
        const APIPATH = "get-system-options"
        
        return axios
            .post(`${API.baseURL}/${APIPATH}`, data,{headers:{token: UserProfile.getToken(), user: UserProfile.getUsername()}} )
            .then(response => {
                const res = response.data?.data || []

                dispatch({ type: records.GET_SYS_OPTIONS_SUCCESSFUL, payload:res });
                return true
            })
            .catch(error => {
                dispatch({ type: records.GET_SYS_OPTIONS_FAILED, payload: error });
                return false
            });
    };
};

export const getFileMvtStats = (type="overdue") => {
    return dispatch => {
        dispatch({ type: records.FILE_MVT_STATS_STARTED });
        
        return axios
            .post(`${API.baseURL}/get-mvt-stats`, { type },{headers:{token: UserProfile.getToken(), user: UserProfile.getUsername()}})
            .then(response => {
                console.log("response ::: -> ", response)
                const res = response.data?.data || []
                dispatch({ type: records.FILE_MVT_STATS_SUCCESSFUL, payload: res });
                return true
            })
            .catch(error => {
                dispatch({ type: records.FILE_MVT_STATS_FAILED, payload: error });
                return false
            });
    };
}

export default {
    getUserRecords,
    getSelectedRecord
};